import * as actionTypes from "../actionTypes.js";

const initialState ={
    subscriptionAmountdata: [],
    loading: false,
    error: null,
}

const subscriptionAmountReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_SUBSCRIPTION_AMOUNT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_SUBSCRIPTION_AMOUNT_SUCCESS:
            return{
                ...state,
                subscriptionAmountdata: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.GET_SUBSCRIPTION_AMOUNT_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default subscriptionAmountReducer;