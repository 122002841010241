import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchUserDataSuccess = (data) => ({
  type: actionTypes.FETCH_USER_DATA_SUCCESS,
  payload: data,
});

let refId = '';

// Action to initiate login and request OTP
export const LogIn = (formData) => async (dispatch) => {
  toast.success("Attempting to log in...");
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  console.log("Inside Action");
  try {
    console.log("Inside try");
    // const { data, status } = await api.login(formData);
    console.log('Attempting to log in with formData:', formData); // Logging before API call
        const { data, status } = await api.login(formData);
        console.log('API call successful:', data, status); // Logging after API call
    console.log("data", data);
    console.log("status", status);
    if (status === 200) {
      refId = data.data.RefId; // Store the RefId from the response
      console.log('reference Id', refId);
      const response = dispatch({ type: actionTypes.SHOW_OTP_INPUT, payload: { showOtpInput: true } });

      toast.success(data.message);
      console.log("response inside action", response);
      return data;
    } else {
      // Handle other status codes if needed
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    toast.error("Login failed. Please try again.");
  } finally {
  }
};

export const fetchUserDetailsSuccess = (data) => ({
  type: actionTypes.FETCH_USER_DETAILS_SUCCESS,
  payload: data,
});

// Action to verify OTP and log the user in
export const verifyOtp = (formData) => async (dispatch) => {
  console.log("formData", formData);
  try {
    const { data, status } = await api.varifyOtp(formData);
    console.log("data inside varifyOtp", data);
    console.log("data inside varifyOtp", status);
    if (status === 200) {
      console.log("data inside varifyOtp status", data);

      dispatch({ type: actionTypes.VERIFY_OTP, payload: data });
      toast.success("OTP Verified Successfully");

      return data;
    } else {
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
      toast.error("OTP Verification Failed");
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    toast.error("An error occurred during OTP verification");
  } finally {
  }
};

export const verifyOtpV2 = async (formData) => {
  console.log("formData", formData);
  try {
    const { data } = await api.varifyOtp(formData);
    console.log("data inside varifyOtpV2", data);
    
    return data;
  } catch (error) {
    console.error("Error in verifyOtpV2:", error);
    return {
      status: 'error',
      message: error.response?.data?.message || 'OTP verification failed'
    };
  }
}

// Action to log the user out
export const Logout = ( navigate) => (dispatch) => {
  
  toast.success("Logging out...");
  localStorage.clear();
  dispatch({ type: actionTypes.LOG_OUT });
  window.location.reload(); // Reload the window to clear state
};

export const getMyAccountDetails = async () => {
  try {
      const data = await api.getMyAccountDetails();
      console.log("My Account Details", data);
      return data;
  } catch (error) {
      return error;
  }
}
