import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Container, Typography, Box, Button, Paper, TextField, FormControl,
    Grid, useTheme, useMediaQuery, LinearProgress,
    Select, MenuItem, InputLabel, Divider,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    InputAdornment, IconButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useSelector, useDispatch } from 'react-redux';
import { getSingleCardDetailsDirectly, createCardForCustomerData, cardPaymentSuccess, submitDigitalCardDetails } from '../../redux/actions/cardsForCustomerActions';
import { createDigitalCardForCustomerData } from '../../redux/actions/digitalCardActions';
import Loading from '../../components/Loading';
import config from '../../config/config';
import axios from 'axios';
import { format, parse } from 'date-fns';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const SmartForm = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();

    // Loading state
    const [loading, setLoading] = useState(true);

    // Show review page or form
    const [showReview, setShowReview] = useState(false);

    // Field navigation state
    const [currentFieldIndex, setCurrentFieldIndex] = useState(0);

    // Card data
    const [cardDetails, setCardDetails] = useState(null);

    // Form data
    const [formData, setFormData] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    // Add state for displaying formatted dates
    const [displayDates, setDisplayDates] = useState({});

    // Dialog state
    const [payNowOpen, setPayNowOpen] = useState(false);

    // Get URL parameters
    const { group_path, card_type, card_id } = params;

    // Get user data from redux store
    const user = useSelector((state) => state.auth?.user?.data);

    // Prepare form fields array
    const [formFields, setFormFields] = useState([]);

    // Setup form fields once card details are loaded
    useEffect(() => {
        if (cardDetails) {
            // Only use custom fields from the card
            const customFields = cardDetails.card_fields || [];

            setFormFields(customFields);
        }
    }, [cardDetails, formData]);

    // Fetch card details on mount
    useEffect(() => {
        const fetchCardDetails = async () => {
            setLoading(true);
            try {
                let cardData = await getSingleCardDetailsDirectly(params.card_id, params.group_path, params.group_type);
                if (cardData) {
                    setCardDetails(cardData.data);

                    // Initialize form data with any field requirements from the card
                    if (cardData.data?.card_fields) {
                        const initialFormData = {};
                        cardData.data.card_fields.forEach(field => {
                            initialFormData[field.field_id] = '';
                        });
                        setFormData(cardData.data.card_fields);
                    }
                }

                setLoading(false);
            } catch (error) {
                console.error("Error fetching card details:", error);
                setLoading(false);
            }
        };

        fetchCardDetails();
    }, [dispatch, params.card_id, params.group_path, params.group_type]);

    // Form field change handlers
    const handleFieldChange = (f1, event) => {
        console.log(`f1: `, f1);
        const value = event.target.value;
        console.log(`value: `, value);
        // Create a new array to trigger React re-rendering
        const updatedFormData = formData.map(f2 => {
            if (f2.field_name === f1.field_name) {
                console.log(`f2: `, f2);
                return { ...f2, field_value: value };
            }
            return f2;
        });
        console.log(`updatedFormData: `, updatedFormData);
        // This will update the state and trigger a re-render
        setFormData([...updatedFormData]);

        console.log(`Updated field ${f1.field_name} with value: ${value}`);
    };

    const handleDateFieldChange = (f1, type, event) => {
        const value = event.target.value;

        // Initialize the date parts array
        let dateParts = ['', '', ''];

        // If there's an existing value, parse it
        if (f1.field_value && typeof f1.field_value === 'string') {
            const parts = f1.field_value.split(' ');
            // Copy existing parts
            for (let i = 0; i < parts.length && i < 3; i++) {
                dateParts[i] = parts[i];
            }
        }

        // Update the appropriate part
        if (type === 'day') {
            dateParts[0] = value;
        } else if (type === 'month') {
            dateParts[1] = value;
        } else if (type === 'year') {
            dateParts[2] = value;
        }

        // Join the parts back into a string
        const newDateValue = dateParts.join(' ').trim();

        // Create a new array to trigger React re-rendering
        const updatedFormData = formData.map(f2 => {
            if (f2.field_name === f1.field_name) {
                return { ...f2, field_value: newDateValue };
            }
            return f2;
        });

        // This will update the state and trigger a re-render
        setFormData([...updatedFormData]);

        console.log(`Updated date field: ${f1.field_name}, new value: ${newDateValue}`);
    };

    // Navigate between fields
    const goToNextField = () => {
        if (validateCurrentField()) {
            if (currentFieldIndex < formFields.length - 1) {
                setCurrentFieldIndex(currentFieldIndex + 1);
            } else {
                // All fields completed, move to review
                setShowReview(true);
            }
        }
    };

    const goToPreviousField = () => {
        if (currentFieldIndex > 0) {
            setCurrentFieldIndex(currentFieldIndex - 1);
        } else {
            // At first field, go back to previous page
            navigate(-1);
        }
    };
    console.log(`formData: `, formData);

    // Validate current field
    const validateCurrentField = () => {
        const field = formFields[currentFieldIndex];
        if (!field) return true;

        if (field.required) {
            if (!field.value || field.value.trim() === '') {
                return false;
            }
        }

        return true;
    };


    // Go back to form from review
    const goBackToForm = () => {
        setShowReview(false);
    };


    const handleSubmit = async () => {
        let body = {
            card_name: cardDetails.card_name,
            card_description: cardDetails.card_description,
            card_fields: formData.map(({ field_name, id, field_value, field_type }) => ({
                field_name,
                id,
                field_value
            })),
            bundle_id: cardDetails.bundle_id,
        };

        let rsp = await submitDigitalCardDetails(params.card_id, body);
        navigate(`/card-submitted-successfully/${rsp.data.bundle_id}`);

    };

    // Render the current field with label and validation
    const renderCurrentField = () => {
        if (formData.length === 0) return null;

        const field = formData[currentFieldIndex];
        const isValid = validateCurrentField();

        return (
            <Box sx={{ mb: 4 }}>
                <Typography variant="h5" sx={{ mb: 3, fontWeight: 500, textAlign: 'center' }}>
                    {cardDetails?.card_name || "Customize Your Invitation"}
                </Typography>

                <Box sx={{ mb: 8 }}>
                    <LinearProgress
                        variant="determinate"
                        value={(currentFieldIndex + 1) * 100 / formFields.length}
                        sx={{ height: 8, borderRadius: 4 }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                        <Typography variant="body2" color="text.secondary">
                            Field {currentFieldIndex + 1} of {formFields.length}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {Math.round((currentFieldIndex + 1) * 100 / formFields.length)}%
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ position: 'relative', mb: 4 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        {field.field_placeholder} 
                    </Typography>

                    {
                        field.field_type === 'textarea' ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                multiline
                                name={field.field_name}
                                rows={field.rows}
                                value={field.field_value}
                                onChange={field.onChange}
                                required={field.required}
                                error={field.required && !field.field_value}
                                helperText={field.required && !field.field_value ? 'This field is required' : ''}
                            />
                        ) : null
                    }


                    {
                        field.field_type === 'string' ?
                            <TextField
                                fullWidth
                                name={field?.field_name}
                                key={`input-${field.id || field.field_id}-${currentFieldIndex}`}
                                id={field?.id}
                                label={field?.field_placeholder}
                                placeholder={field?.field_placeholder}
                                type={field?.field_type}
                                value={field.field_value || ''}
                                required={field.field_required}
                                onChange={event => handleFieldChange(field, event)}
                                InputLabelProps={{ shrink: true }}
                            /> : null
                    }

                    {
                        field.field_type === 'file' ?
                            <TextField
                                fullWidth
                                name={field?.field_name}
                                id={field?.id}
                                label={field?.field_placeholder}
                                placeholder={field?.field_placeholder}
                                type={field?.field_type}
                                value={field.field_default}
                                required={field.field_required}
                                onChange={event => handleFieldChange(field, event)}
                                InputLabelProps={{ shrink: true }}
                            ></TextField> : null
                    }

                    {
                        field.field_type === 'date' ? (
                            <Box>
                                <Grid container spacing={2}>
                                    {/* Day dropdown */}
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel>Day</InputLabel>
                                            <Select
                                                label="Day"
                                                value={(() => {
                                                    const dateValue = field.field_value;
                                                    if (dateValue) {
                                                        const parts = dateValue.split(' ');
                                                        return parseInt(parts[0], 10);
                                                    }
                                                    return '';
                                                })()}
                                                onChange={event => handleDateFieldChange(field, 'day', event)}
                                            >
                                                <MenuItem value=""><em>Select day</em></MenuItem>
                                                {[...Array(31)].map((_, i) => (
                                                    <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {/* Month dropdown */}
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel>Month</InputLabel>
                                            <Select
                                                label="Month"
                                                value={(() => {
                                                    const dateValue = field.field_value;
                                                    if (dateValue) {
                                                        const parts = dateValue.split(' ');
                                                        return parts[1];
                                                    }
                                                    return '';
                                                })()}
                                                onChange={event => handleDateFieldChange(field, 'month', event)}
                                            >
                                                <MenuItem value=""><em>Select month</em></MenuItem>
                                                {['January', 'February', 'March', 'April', 'May', 'June',
                                                    'July', 'August', 'September', 'October', 'November', 'December'].map((month) => (
                                                        <MenuItem key={month} value={month}>{month}</MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {/* Year dropdown */}
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel>Year</InputLabel>
                                            <Select
                                                label="Year"
                                                value={(() => {
                                                    const dateValue = field.field_value;
                                                    if (dateValue) {
                                                        const parts = dateValue.split(' ');
                                                        return parts[2];
                                                    }
                                                    return '';
                                                })()}
                                                onChange={event => handleDateFieldChange(field, 'year', event)}
                                            >
                                                <MenuItem value=""><em>Select year</em></MenuItem>
                                                {(() => {
                                                    const currentYear = new Date().getFullYear();
                                                    const years = [];
                                                    for (let i = 0; i <= 2; i++) {
                                                        years.push(currentYear + i);
                                                    }
                                                    return years.map(year => (
                                                        <MenuItem key={year} value={year}>{year}</MenuItem>
                                                    ));
                                                })()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* Display the complete date below the dropdowns */}
                                {formData[field.id || field.field_id] && (
                                    <Typography variant="body2" sx={{ mt: 2, fontWeight: 'bold', color: 'primary.main' }}>
                                        Selected date: {formData[field.id || field.field_id]}
                                    </Typography>
                                )}

                                {field.field_required && !field.field_value && (
                                    <Typography variant="caption" color="error" sx={{ mt: 1, display: 'block' }}>
                                        This field is required
                                    </Typography>
                                )}
                            </Box>
                        ) : null
                    }
                    {
                        field.field_type === 'time' && (
                            <Box sx={{ width: '100%' }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    {field.field_name || field.field_placeholder} {field.field_required && <span style={{ color: 'red' }}>*</span>}
                                </Typography>

                                <Grid container spacing={1.5}>
                                    {[
                                        '6 AM Onwards',
                                        '7 AM Onwards',
                                        '8 AM Onwards',
                                        '9 AM Onwards',
                                        '10 AM Onwards',
                                        '11 AM Onwards',
                                        '12 PM Onwards',
                                        '1 PM Onwards',
                                        '2 PM Onwards',
                                        '3 PM Onwards',
                                        '4 PM Onwards',
                                        '5 PM Onwards',
                                        '6 PM Onwards',
                                        '7 PM Onwards',
                                        '8 PM Onwards',
                                        '9 PM Onwards'
                                    ].map((option, index) => {
                                        const isSelected = field.field_value === option;
                                        return (
                                            <Grid item xs={6} sm={4} md={3} key={index}>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    onClick={() => {
                                                        const updatedField = { ...field, field_value: option };
                                                        handleFieldChange(updatedField, { 
                                                            target: { 
                                                                name: field.field_name, 
                                                                value: option 
                                                            } 
                                                        });
                                                    }}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        padding: '12px 16px',
                                                        border: isSelected 
                                                            ? '2px solid #2e7d32' 
                                                            : '1px solid #e0e0e0',
                                                        backgroundColor: isSelected 
                                                            ? '#4caf50' 
                                                            : '#ffffff',
                                                        color: isSelected 
                                                            ? '#ffffff' 
                                                            : 'text.primary',
                                                        '&:hover': {
                                                            backgroundColor: isSelected
                                                                ? '#388e3c'
                                                                : '#f5f5f5'
                                                        },
                                                        height: '48px',
                                                        textTransform: 'none',
                                                        transition: 'all 0.3s ease',
                                                        boxShadow: isSelected
                                                            ? '0px 3px 5px rgba(46, 125, 50, 0.2)'
                                                            : 'none',
                                                        position: 'relative',
                                                        overflow: 'hidden',
                                                        '&::after': {
                                                            content: '""',
                                                            position: 'absolute',
                                                            bottom: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: isSelected 
                                                                ? '3px' 
                                                                : '0px',
                                                            backgroundColor: '#2e7d32',
                                                            transition: 'height 0.3s ease'
                                                        }
                                                    }}
                                                >
                                                    <Typography variant="body2" sx={{
                                                        fontWeight: isSelected ? 600 : 400,
                                                        mr: 1
                                                    }}>
                                                        {option}
                                                    </Typography>

                                                    {isSelected && (
                                                        <CheckCircleOutlineIcon
                                                            sx={{ 
                                                                ml: 'auto',
                                                                color: '#ffffff'
                                                            }}
                                                            fontSize="small"
                                                        />
                                                    )}
                                                </Button>
                                            </Grid>
                                        );
                                    })}
                                </Grid>

                                {field.field_required && !field.field_value && (
                                    <Typography variant="caption" color="error" sx={{ mt: 1, display: 'block' }}>
                                        This field is required
                                    </Typography>
                                )}
                            </Box>
                        )
                    }
                    {
                        field.field_type === 'option' ? (
                            <FormControl fullWidth required={field.field_required}>
                                <InputLabel id={`label-${field.id}`}>{field?.field_placeholder}</InputLabel>
                                <Select
                                    labelId={`label-${field.id}`}
                                    name={field?.field_name}
                                    id={field?.id}
                                    value={field?.field_default || 'xyz'}
                                    onChange={event => handleFieldChange(field, event)}
                                >
                                    {field?.field_options.map(option => (
                                        <MenuItem key={option?.id} value={option?.option_value}>
                                            {option?.option_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : null
                    }

                    {
                        field.field_type === 'number' ?
                            <TextField
                                fullWidth
                                name={field?.field_name}
                                id={field?.id}
                                label={field?.field_placeholder}
                                placeholder={field?.field_placeholder}
                                type={field?.field_type}
                                value={field.field_default}
                                required={field.field_required}
                                onChange={event => handleFieldChange(field, event)}
                                InputLabelProps={{ shrink: true }}
                            ></TextField> : null
                    }

                </Box>

                <Divider sx={{ mb: 3 }} />

                {/* Navigation buttons */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        onClick={goToPreviousField}
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>

                    <Button
                        variant="contained"
                        onClick={goToNextField}
                        disabled={!isValid}
                    >
                        {currentFieldIndex === formFields.length - 1 ? 'Review' : 'Save & Next'}
                    </Button>
                </Box>
            </Box>
        );
    };

    return (
        <Box sx={{ py: 1, px: 1 }}>
            <Container maxWidth="md">
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {!showReview ? (
                            <Box sx={{ p: { xs: 2, md: 3 }, mb: 4 }}>
                                {renderCurrentField()}
                            </Box>
                        ) : (
                            <Paper sx={{ p: { xs: 2, md: 3 }, mb: 4 }}>
                                <Typography variant="h5" sx={{ mb: 3, fontWeight: 500, textAlign: 'center' }}>
                                    Review Your Information
                                </Typography>


                                <Grid container spacing={2}>
                                    {/* <Grid item xs={12} md={6}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                Personal Information
                                            </Typography>
                                            <Typography variant="body1">Name: {user?.name || user?.username}</Typography>
                                            <Typography variant="body1">Email: {user?.email}</Typography>
                                            <Typography variant="body1">Mobile: {user?.phone}</Typography>
                                        </Grid> */}

                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                            Card Information
                                        </Typography>
                                        <Typography variant="body1">Card: {cardDetails.card_name}</Typography>
                                        {/* <Typography variant="body1">Price: ₹{cardDetails.sale_price}</Typography> */}
                                        <Typography variant="body1">Type: {cardDetails?.group_detail?.group_type?.toUpperCase()}</Typography>
                                    </Grid>

                                    {formData?.length > 0 && (
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2 }}>
                                                Details
                                            </Typography>
                                            <hr sx={{ my: 2, border: '1px solid #ddd' }} />
                                            {formData.map((field) => (
                                                <Typography key={field.field_id} variant="body1">
                                                    <strong>{field.field_name}:</strong> {field.field_value || '(Not provided)'}
                                                </Typography>
                                            ))}
                                        </Grid>
                                    )}
                                </Grid>
                                <hr sx={{ mt: 2, border: '1px solid #ddd' }} />
                                <Typography variant="body2" sx={{ mb: 3, color: 'text.secondary' }}>
                                    After submit you will be redirected to download invitation page. Also you will receive a link on WhatsApp.
                                </Typography>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button
                                        variant="outlined"
                                        onClick={goBackToForm}
                                    >
                                        Edit Information
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="large"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Paper>
                        )}

                    </>
                )}
            </Container>
        </Box>
    );
};

export default SmartForm; 