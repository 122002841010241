import { toast } from 'react-toastify';
import { initiateSubscriptionOnProfile, reconcileSubscriptionStatus } from '../api';

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const initiateSubscription = async (profileId) => {
  try {
    // Call your API to initiate subscription
    const response = await initiateSubscriptionOnProfile(profileId, {
      // Add any additional form data needed by your API
      currency: "INR",
      description: "Premium Profile Subscription"
    });

    // console.log(`subscription data: `, response.data);

    if (!response.data?.status == "failed") {
      throw new Error(response.data?.message || 'Failed to create subscription');
    }

    

    return await openRazorpayModal(
      response.data.data.razorpay_subscription_id,
      response.data.data.razorpay_key,
      profileId,
      response.data.data.subscription_attempt_id,
      response.data
    );
    // return response.data;
  } catch (error) {
    console.error('Payment initiation failed:', error);
    toast.error(error.message || 'Unable to start payment process. Please try again.');
    throw error;
  }
};

const openRazorpayModal = async (
  subscriptionId,
  rzpKey,
  profileId,
  subscription_attempt_id,
  subscriptionData
) => {
  try {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      toast.error("Razorpay SDK failed to load. Please check your internet connection.");
      return;
    }

    return new Promise((resolve, reject) => {
      const options = {
        key: rzpKey,
        subscription_id: subscriptionId,
        name: subscriptionData.name || "Premium Profile",
        description: subscriptionData.description || "Monthly subscription for premium features",
        currency: subscriptionData.currency || "INR",
        prefill: {
          name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name') || "",
          email: localStorage.getItem('email') || "",
          contact: localStorage.getItem('mobile_number') || ""
        },
        handler: async function (response) {
          try {
            const result = await verifySubscription(response, profileId, subscription_attempt_id);
            resolve(result);
          } catch (error) {
            reject(error);
          }
        },
        modal: {
          ondismiss: function () {
            reject(new Error('Subscription process cancelled'));
          },
          confirm_close: true,
          escape: false
        },
        theme: {
          color: '#4caf50'
        },
        notes: {
          profile_id: profileId.toString()
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    });
  } catch (error) {
    console.error('Payment initialization failed:', error);
    toast.error('Unable to start payment process. Please try again.');
    throw error;
  }
};

const verifySubscription = async (response, profileId, subscription_attempt_id) => {
  try {
    const verificationData = {
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_subscription_id: response.razorpay_subscription_id,
      razorpay_signature: response.razorpay_signature,
      subscription_attempt_id: subscription_attempt_id
    };

    const result = await reconcileSubscriptionStatus(profileId, verificationData);

    if (!result.data?.success) {
      throw new Error(result.data?.message || 'Verification failed');
    }

    toast.success('Subscription activated successfully!');
    return result.data;
  } catch (error) {
    console.error('Subscription verification failed:', error);
    toast.error(error.message || 'Unable to verify subscription. Please contact support.');
    throw error;
  }
}; 