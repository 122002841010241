import React, { useEffect, useState } from "react";
import { Box, Typography, Tabs, Tab, Button, Card, List, ListItem, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TicketIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import Ticket from "../../assets/icons/Ticket.svg"
import { getAllSupportTickets, openInteractions } from "../../redux/actions/reportActions";


const MyTicket = () => {
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const [myTickets, setMyTickets] = useState([]);
  const [ticketStatus, setTicketStatus] = useState();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      const response = await getAllSupportTickets();


      if (response) {
        setMyTickets(response?.data?.data);
        setLoading(false);
      }
    };
    fetchTickets();
  }, []);

  const handleTabChange = (event, newValue) => {
    console.log("new value", newValue);
    setTabValue(newValue);
  };

  const openTickets = myTickets.filter((ticket) => ticket.status === "open");
  const closedTickets = myTickets.filter((ticket) => ticket.status === "closed");







  return (
    <>
      <Box sx={{ p: 3, maxWidth: 900, mx: "auto" }}>
        {/* Heading */}
        <Typography variant="h5" fontWeight="bold">
          My Tickets
        </Typography>

        {/* Description */}
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          If you don't see your tickets, use the menu in the upper right to switch
          to the correct team or account.
        </Typography>

        {/* Tabs */}
        <Box sx={{ mt: 3 }}>
          {/* Tabs for Open & Closed Tickets */}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label={`Open Tickets (${openTickets.length})`} />
              <Tab label={`Closed Tickets (${closedTickets.length})`} />
            </Tabs>

            <Button variant="contained" onClick={() => navigate("/support-ticket")}>
              Create New Ticket
            </Button>
          </Box>

          {/* Show Loader */}
          {loading ? (
            <Typography variant="body1" sx={{ mt: 3, textAlign: "center" }}>
              Loading...
            </Typography>
          ) : tabValue === 0 ? (
            // Open Tickets
            openTickets?.length > 0 ? (
              <List sx={{ mt: 3 }}>
                {openTickets?.map((ticket) => (
                  <Card key={ticket?.id} sx={{ mb: 2, p: 3 }} onClick={() => navigate(`/interactions/${ticket?.id}`)}>
                    <ListItem>
                      <ListItemText
                        primary={`Subject: ${ticket?.subject}`}
                        secondary={`Description: ${ticket?.description}`}
                      />
                      {/* <Typography variant="body2" sx={{ color: "grey.600" }}>
                        Priority: {ticket.priority}
                      </Typography> */}
                    </ListItem>
                  </Card>
                ))}
              </List>
            ) : (
              // No Open Tickets
              <Card
                sx={{
                  mt: 3,
                  p: 5,
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* Open Tickets Content */}
                <Box
                  component="img"
                  src={Ticket}
                  alt="Ticket Icon"
                  sx={{ width: 200, height: 200, mb: 2 }}
                />
                <Typography variant="body1">
                  You don't have any open tickets
                </Typography>
                <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate("/support-ticket")}>
                  Create New Ticket
                </Button>
              </Card>
            )
          ) : closedTickets?.length > 0 ? (
            // Closed Tickets
            <List sx={{ mt: 3 }}>
              {closedTickets?.map((ticket) => (
                <Card key={ticket?.id} sx={{ mb: 2, p: 2 }}>
                  <ListItem>
                    <ListItemText
                      primary={ticket?.subject}
                      secondary={`Description: ${ticket?.description}`}
                    />
                    <Typography variant="body2" sx={{ color: "grey.600" }}>
                      Resolved At: {ticket?.updatedAt}
                    </Typography>
                  </ListItem>
                </Card>
              ))}
            </List>
          ) : (
            // No Closed Tickets
            <Card
              sx={{
                mt: 3,
                p: 5,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TicketIcon sx={{ fontSize: 200, color: "grey.500", mb: 2 }} />
              <Typography variant="body1">You don't have any closed tickets</Typography>
            </Card>
          )}


        </Box>
      </Box>
    </>
  )
}

export default MyTicket