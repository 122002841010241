import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const getSubscriptionAmountRequest = () => ({
    type:actionTypes.GET_SUBSCRIPTION_AMOUNT_REQUEST,
});
export const getSubscriptionAmountSuccess = (data) =>({
    type:actionTypes.GET_SUBSCRIPTION_AMOUNT_SUCCESS,
    payload: data,
});
export const getSubscriptionAmountFailure = (error) =>({
    type:actionTypes.GET_SUBSCRIPTION_AMOUNT_FAILURE,
    payload: error,
});

export const getSubscriptionAmountData = (id) => {
    return async (dispatch) => {
        dispatch(getSubscriptionAmountRequest());
        try{
            const data = await api.getSubcriptionAmount(id);
            dispatch(getSubscriptionAmountSuccess(data));
            return data;
        }catch (error){
            dispatch(getSubscriptionAmountFailure(error));
            
        }
    }
}