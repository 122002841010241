import axios from "axios";
import config from '../config/config';

// Determine the environment based on the frontend URL
const isSandbox = window.location.hostname.includes('sandbox.rajoffset.com') || window.location.hostname.includes('local2host');

const AccountAPIPublic = axios.create({
    baseURL: isSandbox ? `https://sandbox-account.rajoffset.com/` : `https://account.rajoffset.com/`,
    // baseURL: `http://localhost:3000/`,
});

AccountAPIPublic.interceptors.request.use((req) => {
    if (localStorage.getItem("my_access_token")) {
         req.headers.Authorization = `Bearer ${localStorage.getItem("my_access_token").replaceAll('"', '')}`;
     }
     return req;
 });

AccountAPIPublic.interceptors.response.use(
    (response) => {
        // console.log("response from API", response);
        return response
    },
    (error) => {
    console.log("Interceptor Error", error);
    if (error.response && error.response?.data) {
        return Promise.reject(error.response?.data);
    }
    return Promise.reject(error.message);
});

const APIEnginePublic = axios.create({
    baseURL: isSandbox ? `https://sandbox-engine.rajoffset.com/` : `https://engine.rajoffset.com/`,
    // baseURL: `http://localhost:6000/`,
});

const APIEnginePrivate = axios.create({
    baseURL: isSandbox ? `https://sandbox-engine.rajoffset.com/` : `https://engine.rajoffset.com/`,
});

APIEnginePrivate.interceptors.request.use((req) => {
    if (localStorage.getItem("my_access_token")) {
        console.log("LocalStorage", localStorage.getItem("my_access_token"));
        req.headers.Authorization = `Bearer ${localStorage.getItem("my_access_token").replaceAll('"', '')}`;

    }
    return req;
});

APIEnginePrivate.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response?.data) {
            return Promise.reject(error.response?.data);
        }
        return Promise.reject(error.message);
    }
);

const api = axios.create({
    baseURL: config.apiUrl,
    headers: {
        'Content-Type': 'application/json',
        // Add any other default headers here
    }
});

// Add request interceptor
api.interceptors.request.use(
    (config) => {
        // You can modify the request config here
        // For example, add authentication tokens
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add response interceptor
api.interceptors.response.use(
    (response) => {
        // You can modify the response here
        return response;
    },
    (error) => {
        // Handle errors globally
        return Promise.reject(error);
    }
);

export const login = (form) => AccountAPIPublic.post(`/passwordless/v1/send-otp/`, form);

export const varifyOtp = (form) => AccountAPIPublic.post(`/passwordless/v1/verify-otp/`, form);

export const getMyAccountDetails = () => AccountAPIPublic.get(`/passwordless/v1/get-account-details/`);

export const faq = (form) => AccountAPIPublic.get(`common/web/faq/v1/`, form);

export const reportIssue = (form) => AccountAPIPublic.get(`common/web/report-issue/v1/`, form);

export const createReportIssue = (form) => AccountAPIPublic.post(`common/web/report-issue/v1/`, form);

export const newDesign = () => AccountAPIPublic.get(`common/web/new-design/v1/`);

export const newDesignRequest = (form) => AccountAPIPublic.post(`common/web/new-design/v1/`,);

export const getAllCardsByGroupNameAndGroupType = (group_path, group_type, page, limit) => APIEnginePublic.get(`/invitation/card/v1/?group_path=${group_path}&group_type=${group_type}&page=${page}&limit=${limit}`);

export const getSingleCardDetails = (card_id, group_path, group_type) => APIEnginePublic.get(`/invitation/card/v1/${card_id}/?group_path=${group_path}&group_type=${group_type}`);

export const getSidebarItems = () => APIEnginePublic.get(`/invitation/group/v1/`);

export const createCardForCustomer = (id, form) => APIEnginePublic.post(`/invitation/card/v1/${id}`, form);

export const submitDigitalCardDetails = (id, form) => APIEnginePrivate.post(`/invitation/card/v2/${id}/smart-form`, form);

export const signedUrlServer = (form) => APIEnginePublic.post(`/invitation/signed-url/`, form);

export const cardPaymentSuccess = (id, form) => APIEnginePrivate.post(`/invitation/card-payment-success/v1/${id}`, form);

export const getAllCardsSuggestion = (group_path, group_type, page, limit) => APIEnginePrivate.get(`/invitation/card-suggestion/v1/?group_path=${group_path}&group_type=${group_type}&page=${page}&limit=${limit}`);

export const getAllCards = (page, limit) => APIEnginePrivate.get(`/invitation/card-attempt/v1/`);

export const completeProfileWithUserDetails = (form) => APIEnginePrivate.post(`/web/v1/complete-profile/`,form);

export const getAllProfiles = () => AccountAPIPublic.get(`/web-profiles/v1/`);

export const createProfile = (form) => AccountAPIPublic.post(`/web-profiles/v1/`,form);

export const updateProfile = (id, form) => AccountAPIPublic.put(`/web-profiles/v1/${id}`, form);

export const getSingleProfile = (id) => AccountAPIPublic.get(`/web-profiles/v1/${id}`);

export const deleteProfile = (id) => AccountAPIPublic.delete(`/web-profiles/v1/${id}`);

export const createCardForDiwaliOffer = (form) => APIEnginePrivate.post(`/invitation/multiple-card/v1/`, form);

export const getBundles = (form) => APIEnginePrivate.post(`/invitation/card-bundle-details/v1`,form);
export const getIndividualCard = (form) => APIEnginePrivate.post(`/invitation/card-details/v1`,form);

export const bundlePaymentBeacon = (bundle_id) => APIEnginePrivate.get(`/invitation/card-bundle-details/v1/payment-check/${bundle_id}`);
export const bundlePaymentSuccess = (form) => APIEnginePrivate.post(`/invitation/card-bundle-details/v1/payment-step2`, form);
export const individualCardPaymentSuccess = (form) => APIEnginePrivate.post(`/invitation/card-details/v1/payment-step2`, form);

export const attemptDigitalCardEntry = (card_id, form) => APIEnginePrivate.post(`/invitation/card/v2/${card_id}`, form);

export const markDigitalCard = (id, form) => APIEnginePrivate.post(`/invitation/digital-invite-card/v1/${id}`, form);

export const getAllDigitalCard = () => APIEnginePrivate.get(`/invitation/digital-invite-card-attempt/v1/`);

export const sendDigitalInvitation = (id, form) => APIEnginePrivate.post(`/invitation/digital-invite-card-send/v1/${id}`, form);

export const getSingleDigitalCard = (id) => APIEnginePrivate.get(`/invitation/digital-invite-card-attempt/v1/${id}`);

export const updateDigitalInvitationCard = (id,form) => APIEnginePrivate.put(`/invitation/digital-invite-card-attempt/v1/${id}`, form);

export const deleteDigitalCard = (id) => APIEnginePrivate.delete(`/invitation/digital-invite-card-attempt/v1/${id}`);

export const viewDigitalInvitationCard = (form) => APIEnginePublic.post(`/invitation/view-digital-invite-card/v1/`,form);

export const getAllMembers = (id) => APIEnginePrivate.get(`/invitation/digital-invite-card-member/v1/${id}`);

export const addMember = (id,form) => APIEnginePrivate.post(`/invitation/digital-invite-card-member/v1/${id}`, form);

export const getAllCardGroup = () => APIEnginePublic.get(`/invitation/group/v1/`);

export const initiateSubscriptionOnProfile = (entry_id, form) => APIEnginePrivate.post(`/political/web/subscription/v1-ext/${entry_id}/initiate`,form);
export const reconcileSubscriptionStatus = (entry_id, form) => APIEnginePrivate.post(`/political/web/subscription/v1-ext/${entry_id}/reconcile`,form);

export const getSubcriptionAmount = (entry_id) => APIEnginePrivate.post(`/political/web/subscription/v1-ext/${entry_id}/details`);

export const createSupportTicket = (form) => AccountAPIPublic.post(`/my-support/v1/tickets`, form);
export const getSupportTickets = () => AccountAPIPublic.get(`/my-support/v1/tickets`);
export const openItraction = (id) => AccountAPIPublic.get(`/my-support/v1/tickets/${id}/interactions`);
export const createItraction = (id,form) => AccountAPIPublic.post(`/my-support/v1/tickets/${id}/interactions`,form);

export default api;



