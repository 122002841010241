import React, { useState, useEffect } from 'react';
import { 
  Button, 
  TextField, 
  Container, 
  Typography, 
  Box, 
  CircularProgress, 
  Paper,
  Fade,
  Divider,
  InputAdornment,
  IconButton,
  Link
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyOtp, LogIn } from '../redux/actions/authActions';
import { createDigitalCardV2 } from '../redux/actions/digitalCardActions';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import LockIcon from '@mui/icons-material/Lock';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VerifiedIcon from '@mui/icons-material/Verified';

const StyledButton = styled(Button)`
  background: linear-gradient(45deg, #2196F3 30%, #21CBF3 90%);
  box-shadow: 0 3px 5px 2px rgba(33, 203, 243, .3);
  padding: 12px 0;
  transition: all 0.3s ease;
  
  &:hover {
    background: linear-gradient(45deg, #1976d2 30%, #0ba3d4 90%);
    box-shadow: 0 4px 10px 2px rgba(33, 203, 243, .4);
    transform: translateY(-2px);
  }
`;

const StyledPaper = styled(Paper)`
  padding: 40px;
  border-radius: 18px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  background: linear-gradient(145deg, #ffffff, #f5f8ff);
  border-top: 4px solid #2196F3;
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
  }
`;

const OtpTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: 56px;
    border-radius: 12px;
    font-size: 1.2rem;
    letter-spacing: 2px;
  }
  
  .MuiOutlinedInput-input {
    text-align: center;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
  }
  
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #2196F3;
  }
`;

const VerifyOtp = () => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [remainingTime, setRemainingTime] = useState(120); // 2 minutes countdown
  const [resending, setResending] = useState(false);
  
  const { 
    referenceId, 
    mobileNumber, 
    tenant, 
    message, 
    countryCode, 
    otpMethod,
    redirectUrl,
    requiresCardAttempt,
    cardData
  } = location.state || {};

  // OTP countdown timer
  useEffect(() => {
    if (remainingTime > 0) {
      const timer = setTimeout(() => {
        setRemainingTime(prevTime => prevTime - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [remainingTime]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const handleResendOtp = async () => {
    setResending(true);
    try {
      const response = await dispatch(LogIn({ 
        mobile: mobileNumber, 
        tenant: tenant, 
        country_code: countryCode, 
        otp_method: otpMethod 
      }));
      
      if (response && response.data && response.data.RefId) {
        setRemainingTime(120); // Reset timer
        toast.success("OTP resent successfully!");
        // Update referenceId with the new one
        location.state.referenceId = response.data.RefId;
      } else {
        toast.error("Failed to resend OTP. Please try again.");
      }
    } catch (err) {
      toast.error("Failed to resend OTP. Please try again.");
    } finally {
      setResending(false);
    }
  };

  const handleSubmitOtp = async () => {
    setLoading(true);
    setError('');
    const otpData = { OTP: otp, RefId: referenceId, mobile: mobileNumber, tenant: tenant, country_code: countryCode };
    try {
      const response = await dispatch(verifyOtp(otpData));
      if (response && response.status === 'success') {
        toast.success("OTP verification successful!");
        
        if (requiresCardAttempt && cardData) {
          // Create card attempt after successful login
          try {
            const body = {
              card_name: cardData.card_name,
              card_description: cardData.card_description,
              card_id: cardData.card_id,
            };
            
            const rsp = await createDigitalCardV2(cardData.card_id, body);
            
            if (rsp && rsp.data && rsp.data.id) {
              toast.success("Card form initialized successfully!");
              navigate(`/cards/${cardData.group_path}/${cardData.group_type}/${cardData.card_id}/${rsp.data.id}/smart-form`);
            } else {
              // Redirect to original card page if attempt creation fails
              toast.error("Could not initialize form. Please try again.");
              navigate(redirectUrl || '/dashboard/my-cards');
            }
          } catch (error) {
            console.error("Error creating card attempt:", error);
            toast.error("An error occurred while creating your card. Please try again.");
            navigate(redirectUrl || '/dashboard/my-cards');
          }
        } else {
          // Just redirect if no card attempt needed
          navigate(redirectUrl || '/dashboard/my-cards');
        }
      } else {
        setError('OTP verification failed. Please try again.');
      }
    } catch (err) {
      setError('OTP verification failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to previous page
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          my: 8 
        }}
      >
        <Fade in={true} timeout={800}>
          <StyledPaper elevation={3}>
            <Box sx={{ position: 'relative' }}>
              <IconButton 
                onClick={handleGoBack}
                sx={{ position: 'absolute', top: -15, left: -15 }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Box>
            
            <Box textAlign="center" mb={4}>
              <Typography 
                component="h1" 
                variant="h4" 
                fontWeight={600} 
                color="primary"
                sx={{ mb: 1 }}
              >
                Verification
              </Typography>
              
              <Divider sx={{ my: 2 }} />
              
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  my: 2,
                  gap: 1
                }}
              >
                <LockIcon color="primary" />
                <Typography variant="body2" color="text.secondary">
                  Secure Authentication
                </Typography>
              </Box>
              
              <Box sx={{ mt: 3, mb: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Enter Verification Code
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  We've sent a code to <strong>{countryCode} {mobileNumber}</strong>
                </Typography>
              </Box>
            </Box>

            {/* OTP Input Field */}
            <Box sx={{ mb: 5 }}>
              <OtpTextField
                margin="normal"
                required
                fullWidth
                label="Verification Code"
                value={otp}
                onChange={(e) => setOtp(e.target.value.replace(/[^0-9]/g, ''))}
                inputProps={{ maxLength: 6 }}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <VerifiedIcon color="disabled" />
                    </InputAdornment>
                  )
                }}
              />
              
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  mt: 1
                }}
              >
                <Typography variant="caption" color="text.secondary">
                  Time remaining: {formatTime(remainingTime)}
                </Typography>
                
                <Link 
                  component="button" 
                  variant="body2"
                  onClick={handleResendOtp}
                  disabled={remainingTime > 0 || resending}
                  sx={{ 
                    textDecoration: 'none',
                    opacity: remainingTime > 0 ? 0.5 : 1,
                    cursor: remainingTime > 0 ? 'not-allowed' : 'pointer'
                  }}
                >
                  {resending ? 'Sending...' : 'Resend Code'}
                </Link>
              </Box>
            </Box>

            {error && (
              <Typography 
                color="error" 
                sx={{ mt: 1, mb: 3, textAlign: 'center' }}
              >
                {error}
              </Typography>
            )}

            <StyledButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmitOtp}
              disabled={otp.length < 4 || loading}
              sx={{ mt: 1, mb: 2, borderRadius: '12px', fontSize: '1rem' }}
            >
              {loading ? (
                <>
                  <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                  Verifying...
                </>
              ) : 'Verify & Continue'}
            </StyledButton>
            
            <Typography 
              variant="caption" 
              color="text.secondary" 
              align="center" 
              display="block"
            >
              Make sure to enter the correct code received on your phone
            </Typography>
          </StyledPaper>
        </Fade>
      </Box>
    </Container>
  );
};

export default VerifyOtp;
