import { Box, Typography, Container, Paper, Link } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

function TermsAndConditions() {
    return (
        <Box sx={{ 
            py: { xs: 4, md: 8 },
            backgroundColor: '#f8f9fa',
            minHeight: '100vh'
        }}>
            <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3 } }}>
                <Paper elevation={0} sx={{ 
                    p: { xs: 3, md: 6 },
                    borderRadius: '16px',
                    backgroundColor: '#fff'
                }}>
                    <Typography variant="h2" sx={{
                        fontWeight: '800',
                        color: '#D9376E',
                        mb: 3,
                        fontSize: { xs: '2rem', md: '2.8rem' },
                        textAlign: 'center'
                    }}>
                        Terms and Conditions
                    </Typography>

                    <Box sx={{ 
                        '& > section': {
                            mb: 4
                        },
                        '& h3': {
                            fontWeight: '700',
                            color: '#D9376E',
                            mb: 2,
                            fontSize: '1.4rem'
                        },
                        '& p': {
                            color: '#555',
                            mb: 2,
                            lineHeight: '1.7'
                        },
                        '& ul': {
                            pl: 4,
                            mb: 2
                        },
                        '& li': {
                            mb: 1
                        },
                        '& a': {
                            color: '#D9376E',
                            fontWeight: '600',
                            textDecoration: 'none',
                            '&:hover': {
                                textDecoration: 'underline'
                            }
                        }
                    }}>
                        <section>
                            <Typography>
                                Awesome Innovators (<strong>“Firm”</strong>) owns and operates the website www.rajoffset.com (and affiliate websites awesomeinnovators.com) (collectively and individually referred to herein as the <strong>“Website”</strong>). By accessing the Website, you agree with and consent to be bound by these Terms and Conditions and our <Link component={RouterLink} to="/privacy-policy">Privacy Policy</Link>.
                            </Typography>
                        </section>

                        {[
                            "Nature of the Website",
                            "Eligibility to Use the Website",
                            "Registration",
                            "Your Use of the Website",
                            "Fees and Payment",
                            "Usage of Contact Number/Email",
                            "Onus of the User",
                            "Disclaimers of Warranties",
                            "Intellectual Property",
                            "Indemnity",
                            "Limitation of Liability",
                            "Privacy Policy",
                            "Entire Agreement",
                            "Assignment",
                            "Amendment",
                            "Governing Law",
                            "Dispute Resolution"
                        ].map((title, index) => (
                            <section key={index}>
                                <Typography variant="h3">{title}</Typography>
                                <Typography>
                                    {[
                                        "The Website intends to act as a platform to assist companies, groups and individuals to send digital invitations, purchase invitation cards, subscribe to printed invitation cards, and manage digital invoices.",
                                        "You must be at least 18 years old to use this Website. You warrant that you have the legal authority to create binding obligations and will use the Website in accordance with these Terms.",
                                        "To access services, you must register an account. You agree to provide accurate information and maintain the security of your account credentials.",
                                        "You agree not to misuse the Website or its content. This includes restrictions on commercial use, automated access, and unauthorized modifications.",
                                        "We reserve the right to charge fees for listings and transactions. Users are responsible for all charges, fees, and taxes associated with their use of the services.",
                                        "We may use your contact information to send transaction-related communications. You consent to receive such communications via SMS, email, or voice calls.",
                                        "You are solely responsible for your use of the Website and any transactions you conduct through it. You must comply with all applicable laws and regulations.",
                                        "The Website is provided 'as is' without warranties of any kind. We disclaim all warranties, including merchantability and fitness for a particular purpose.",
                                        "All content on the Website, except user-submitted content, is owned by the Company. You agree not to use any proprietary information without permission.",
                                        "You agree to indemnify the Company against any claims arising from your use of the Website, breach of these Terms, or violation of any laws.",
                                        "The Company shall not be liable for any direct, indirect, incidental, or consequential damages arising from your use of the Website.",
                                        "Our Privacy Policy governs the collection and use of your personal information. By using the Website, you agree to our Privacy Policy.",
                                        "These Terms, including the Privacy Policy, constitute the entire agreement between you and the Company regarding your use of the Website.",
                                        "You may not assign these Terms without our written consent. We may assign these Terms at our discretion.",
                                        "We reserve the right to modify these Terms at any time. Your continued use of the Website constitutes acceptance of the modified Terms.",
                                        "These Terms are governed by the laws of India. Any disputes shall be subject to the exclusive jurisdiction of courts in Mirzapur, Uttar Pradesh.",
                                        "Any disputes shall be resolved through arbitration in Mirzapur, Uttar Pradesh, in accordance with the Arbitration and Conciliation Act, 1996."
                                    ][index]}
                                </Typography>
                            </section>
                        ))}

                        <Typography variant="body2" sx={{ 
                            mt: 4,
                            color: '#666',
                            fontStyle: 'italic',
                            textAlign: 'center'
                        }}>
                            Last updated: {new Date().toLocaleDateString()}
                        </Typography>
                    </Box>
                </Paper>
            </Container>
            </Box>
    )
}

export default TermsAndConditions