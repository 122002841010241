import * as actionTypes from "../actionTypes.js";

const initialState = {
    individualCardData: [],
    loading: false,
    error: null,
}

const individualCardDetailReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.GET_INDIVIDUAL_CARD_REQUEST:
            return{
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.GET_INDIVIDUAL_CARD_SUCCESS:
            return{
                ...state,
                loading: false,
                individualCardData: action.payload,
                error: null,
            };
        case actionTypes.GET_INDIVIDUAL_CARD_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
};
export default individualCardDetailReducer