import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Typography, Grid, Box, Paper } from '@mui/material';
import { fetchFaqData } from '../../redux/actions/faqActions';

const FAQ = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchFaqsData = async () => {
     await dispatch(fetchFaqData());
    };
    fetchFaqsData();
  }, [dispatch])

  return (
    <Box sx={{ 
      py: { xs: 4, md: 8 },
      backgroundColor: '#f8f9fa',
      minHeight: '100vh'
    }}>
      <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3 } }}>
        <Box sx={{ 
          textAlign: 'center',
          mb: { xs: 4, md: 6 },
          px: { xs: 1, sm: 0 }
        }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: '800',
              color: '#D9376E',
              mb: 2,
              fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.8rem' },
              lineHeight: '1.2'
            }}
          >
            Frequently Asked Questions
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '0.95rem', sm: '1.05rem' },
              color: '#666',
              maxWidth: '800px',
              mx: 'auto',
              lineHeight: '1.6'
            }}
          >
            We've compiled a list of frequently asked questions to help you understand our digital invitation card services better.
          </Typography>
        </Box>
        
        <Grid container spacing={3} sx={{ 
          justifyContent: 'center',
          '& > .MuiGrid-item': {
            display: 'flex',
            justifyContent: 'center'
          }
        }}>
          {faqItems.map((item, index) => (
            <Grid item xs={12} sm={10} md={6} key={index}>
              <Paper
                elevation={0}
                sx={{
                  p: { xs: 2, sm: 3 },
                  width: '100%',
                  borderRadius: '16px',
                  backgroundColor: '#fff',
                  border: '1px solid #eee',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 8px 24px rgba(0,0,0,0.08)',
                    borderColor: '#D9376E'
                  }
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: '700',
                    color: '#D9376E',
                    mb: 1.5,
                    fontSize: { xs: '1.1rem', sm: '1.2rem' },
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                      content: '"Q."',
                      display: 'inline-block',
                      fontWeight: '800',
                      mr: 1.5,
                      color: '#D9376E'
                    }
                  }}
                >
                  {item.question}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#555',
                    fontSize: { xs: '0.95rem', sm: '1rem' },
                    lineHeight: '1.7',
                    pl: 4.5,
                    position: 'relative',
                    '&::before': {
                      content: '"A."',
                      position: 'absolute',
                      left: 0,
                      fontWeight: '800',
                      color: '#D9376E'
                    }
                  }}
                >
                  {item.answer}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

const faqItems = [
  { question: 'What types of invitation cards do you offer?', answer: 'We offer a wide range of digital invitation cards including Wedding, Engagement, Muslim Wedding, Haldi, Sangeet, Mehndi, Anniversary, Tilak Ceremony, Birthday, 1st Birthday, Mundan, Annaprashan, Thread Ceremony, Naming Ceremony, House Warming, Shop Opening, Vastu Shanti, Ganpati, Kitty Party, Shok Sandesh, Maa Ki Chowki, Satyanarayana Pooja, and Bhandara invitations.' },
  { question: 'Do I need any technical skills to create invitations?', answer: 'No technical skills are required. Our system is user-friendly and guides you through a simple form to create your invitation.' },
  { question: 'How long does it take to get my invitation?', answer: 'You will receive your digital invitation card and video within 10 minutes of completing the form.' },
  { question: 'What languages do you support?', answer: 'We support Hindi, English, and all major Indian languages.' },
  { question: 'Can I customize the invitation templates?', answer: 'Yes, our templates are fully customizable. You can add your personal details, photos, and other information.' },
  { question: 'How do I download my invitation?', answer: 'Once your invitation is ready, you will receive a download link via email and on your dashboard.' },
  { question: 'Can I share my invitation directly from your platform?', answer: 'Yes, you can share your invitation via WhatsApp, email, or social media directly from our platform.' },
  { question: 'What file formats do you provide?', answer: 'We provide invitations in PDF format for cards and MP4 format for videos.' },
  { question: 'Can I make changes to my invitation after it\'s created?', answer: 'Yes, you can edit your invitation anytime before downloading it.' },
  { question: 'Do you offer video invitations?', answer: 'Yes, we offer both digital cards and video invitations.' },
  { question: 'How many times can I download my invitation?', answer: 'You can download your invitation unlimited times within 30 days of creation.' },
  { question: 'Can I use my own photos in the invitations?', answer: 'Yes, you can upload and use your own photos in the invitations.' },
  { question: 'What if I\'m not satisfied with the result?', answer: 'We offer unlimited revisions until you\'re satisfied with your invitation.' },
  { question: 'Do you provide customer support?', answer: 'Yes, we provide 24/7 customer support via chat, email, and phone.' },
  { question: 'Can I preview my invitation before downloading?', answer: 'Yes, you can preview both the card and video before downloading.' },
  { question: 'What payment methods do you accept?', answer: 'We accept all major credit/debit cards, UPI, and net banking.' },
  { question: 'Is my personal information safe?', answer: 'Yes, we use industry-standard encryption to protect your personal information.' },
  { question: 'Can I order invitations in bulk?', answer: 'Yes, we offer special discounts for bulk orders.' },
  { question: 'Do you offer rush delivery?', answer: 'Yes, we offer express delivery options for urgent requirements.' },
  { question: 'Can I see samples of your work?', answer: 'Yes, we have a gallery of sample invitations on our website.' },
  { question: 'What if I need help filling out the form?', answer: 'Our support team is available to assist you with filling out the form.' },
  { question: 'Can I save my invitation design for future use?', answer: 'Yes, you can save your design and reuse it for future events.' },
  { question: 'Do you offer printing services?', answer: 'Yes, we offer printing services at a very affordable price.' },
  { question: 'Can I get a refund if I\'m not satisfied?', answer: 'We offer a 100% satisfaction guarantee and will refund your payment if you\'re not satisfied within 24 hours of purchase for limited products.' },
  { question: 'How do I contact customer support?', answer: 'You can contact us through the support chat on our website, email at support@awesomeinnovators.com, or call us at +91-87651 40002' },
  { question: 'Support Timing', answer: 'We are available from 10:00 AM to 5:00 PM (IST) for support from Monday to Saturday. Except on Sundays and National Holidays. On Holidays we are available for emergency support only.' }
];

export default FAQ;