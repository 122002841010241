import React from 'react';
import { Typography, Box, Container, Grid, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StorefrontIcon from '@mui/icons-material/Storefront';
import DevicesIcon from '@mui/icons-material/Devices';
import TimelineIcon from '@mui/icons-material/Timeline';

const AboutUsPage = () => {
    const theme = useTheme();

    return (
        <Box sx={{ padding: { xs: '30px 0', md: '50px 0' } }}>
            <Container maxWidth="md">
                <Typography
                    variant="h3"
                    sx={{
                        fontWeight: 'bold',
                        background: 'linear-gradient(45deg, #0097a7 30%, #00bcd4 90%)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        mb: 4,
                        textAlign: 'center',
                        fontSize: { xs: '2rem', md: '3rem' }
                    }}
                >
                    ABOUT US
                </Typography>

                {/* Journey Timeline */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 4,
                    mb: 6,
                    flexWrap: 'wrap'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: '#00bcd4'
                    }}>
                        <DevicesIcon />
                        <Typography variant="h6">Now Digitally available Since 2017</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: '#4caf50'
                    }}>
                        <TimelineIcon />
                        <Typography variant="h6">Growing Strong</Typography>
                    </Box>
                </Box>

                <Box
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                        padding: { xs: '40px 20px', md: '70px 40px' },
                        borderRadius: '16px',
                        boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                        position: 'relative',
                        overflow: 'hidden',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            height: '4px',
                            background: 'linear-gradient(45deg, #0097a7 30%, #00bcd4 90%)'
                        }
                    }}
                >
                    <Typography 
                        variant="h4" 
                        gutterBottom 
                        sx={{ 
                            marginBottom: '30px',
                            fontWeight: 600,
                            color: theme.palette.primary.main
                        }}
                    >
                        WHO WE ARE
                    </Typography>

                    <Typography variant="body1" paragraph sx={{ 
                        marginBottom: '30px', 
                        lineHeight: '1.8',
                        fontSize: '1.1rem'
                    }}>
                        Welcome to Raj Offset, where tradition meets innovation! With over 30 years of experience in the printing industry, we have proudly established ourselves as a trusted name in creating exquisite invitation cards.
                    </Typography>

                    {/* Digital Journey Highlight */}
                    <Box sx={{
                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0, 151, 167, 0.1)' : 'rgba(0, 151, 167, 0.05)',
                        padding: '24px',
                        borderRadius: '12px',
                        marginBottom: '30px',
                        border: '1px solid rgba(0, 151, 167, 0.2)'
                    }}>
                        <Typography variant="h6" sx={{ 
                            color: '#0097a7',
                            marginBottom: '12px',
                            fontWeight: 600
                        }}>
                            Our Digital Evolution
                        </Typography>
                        <Typography variant="body1" sx={{ lineHeight: '1.8' }}>
                            Since 2017, we've embraced the digital revolution, bringing our decades of expertise to the online world. This transformation has allowed us to serve customers nationwide, offering the same quality and creativity through our digital platform while maintaining the personal touch that has always set us apart.
                        </Typography>
                    </Box>

                    <Typography variant="h5" gutterBottom sx={{ 
                        marginBottom: '20px',
                        color: theme.palette.primary.main
                    }}>
                        Our Specialties
                    </Typography>

                    <Grid container spacing={2} sx={{ marginBottom: '30px' }}>
                        {[
                            'Shaadi Cards',
                            'Engagement Invitations',
                            'Haldi & Mehndi Cards',
                            'Birthday Invitations',
                            'Khatha Cards',
                            'Mundan & Barahi Invitations',
                            'Visiting Cards'
                        ].map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Box sx={{
                                    padding: '12px',
                                    borderRadius: '8px',
                                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.02)',
                                    textAlign: 'center'
                                }}>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                        {item}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Rest of the sections with enhanced styling */}
                    {[
                        {
                            title: 'A Legacy of Quality',
                            content: 'With three decades of experience, we have honed our craft to deliver not just products but cherished memories. Our commitment to quality ensures that every card we produce reflects the love and joy of your celebrations.'
                        },
                        {
                            title: 'Affordable Pricing',
                            content: 'We believe that beautiful invitations should be accessible to everyone. Our competitive pricing allows you to create stunning digital cards and videos without breaking the bank.'
                        },
                        {
                            title: 'Seamless Online Experience',
                            content: 'Our website and mobile app are designed with you in mind. Enjoy a hassle-free experience as you browse our extensive collection, customize your designs, and place orders—all from the comfort of your home.'
                        },
                        {
                            title: 'Personalized Touch',
                            content: "We understand that each celebration is unique. That's why we offer customizable templates and designs tailored to your specific needs. Our dedicated team is here to assist you every step of the way, ensuring your vision comes to life."
                        },
                        {
                            title: 'Eco-Friendly Practices',
                            content: 'In addition to our commitment to quality and affordability, we prioritize sustainability. Our printing processes are designed to minimize waste and reduce our environmental footprint, so you can celebrate responsibly.'
                        }
                    ].map((section, index) => (
                        <Box key={index} sx={{ marginBottom: '30px' }}>
                            <Typography variant="h6" gutterBottom sx={{ 
                                color: theme.palette.primary.main,
                                fontWeight: 600,
                                marginBottom: '12px'
                            }}>
                                {section.title}
                            </Typography>
                            <Typography variant="body1" sx={{ 
                                lineHeight: '1.8',
                                color: theme.palette.text.secondary
                            }}>
                                {section.content}
                            </Typography>
                            {index !== 4 && <Divider sx={{ mt: 3 }} />}
                        </Box>
                    ))}
                </Box>
            </Container>
        </Box>
    );
};

export default AboutUsPage;