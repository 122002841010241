import { Box, Typography, Container, Paper } from '@mui/material'
import React from 'react'

function PrivacyPolicy() {
    return (
        <Box sx={{ 
            py: { xs: 4, md: 8 },
            backgroundColor: '#f8f9fa',
            minHeight: '100vh'
        }}>
            <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3 } }}>
                <Paper elevation={0} sx={{ 
                    p: { xs: 3, md: 6 },
                    borderRadius: '16px',
                    backgroundColor: '#fff'
                }}>
                    <Typography variant="h2" sx={{
                        fontWeight: '800',
                        color: '#D9376E',
                        mb: 3,
                        fontSize: { xs: '2rem', md: '2.8rem' },
                        textAlign: 'center'
                    }}>
                        Privacy Policy
                    </Typography>

                    <Box sx={{ 
                        '& > section': {
                            mb: 4
                        },
                        '& h3': {
                            fontWeight: '700',
                            color: '#D9376E',
                            mb: 2,
                            fontSize: '1.4rem'
                        },
                        '& p': {
                            color: '#555',
                            mb: 2,
                            lineHeight: '1.7'
                        }
                    }}>
                        <section>
                            <Typography variant="h3">Introduction</Typography>
                            <Typography>
                                Awesome Innovators, in collaboration with Raj Offset, operates the website and Digital Invitation mobile application (the "Service"). We are committed to protecting your privacy and handling your data in an open and transparent manner.
                            </Typography>
                        </section>

                        <section>
                            <Typography variant="h3">Data Collection</Typography>
                            <Typography>
                                We collect various types of information to provide and improve our Service:
                            </Typography>
                            <Typography component="ul" sx={{ pl: 4 }}>
                                <li>Personal Data: Name, email, phone number, address</li>
                                <li>Usage Data: IP address, browser type, device information</li>
                                <li>Cookies: Session, security, and preference cookies</li>
                                <li>Payment Information: For processing transactions</li>
                            </Typography>
                        </section>

                        <section>
                            <Typography variant="h3">Data Usage</Typography>
                            <Typography>
                                We use collected data for:
                            </Typography>
                            <Typography component="ul" sx={{ pl: 4 }}>
                                <li>Providing and maintaining our Service</li>
                                <li>Improving and personalizing user experience</li>
                                <li>Processing transactions and sending notifications</li>
                                <li>Security and fraud prevention</li>
                                <li>Legal compliance and regulatory requirements</li>
                            </Typography>
                        </section>

                        <section>
                            <Typography variant="h3">Data Sharing</Typography>
                            <Typography>
                                We may share your information with:
                            </Typography>
                            <Typography component="ul" sx={{ pl: 4 }}>
                                <li>Service providers for processing and storage</li>
                                <li>Payment processors for transaction handling</li>
                                <li>Legal authorities when required by law</li>
                                <li>Affiliates within the Awesome Innovators network</li>
                            </Typography>
                            <Typography>
                                We ensure all third parties maintain the same level of data protection as we do.
                            </Typography>
                        </section>

                        <section>
                            <Typography variant="h3">Data Security</Typography>
                            <Typography>
                                We implement industry-standard security measures:
                            </Typography>
                            <Typography component="ul" sx={{ pl: 4 }}>
                                <li>Encryption of sensitive data</li>
                                <li>Regular security audits</li>
                                <li>Access controls and authentication</li>
                                <li>Secure data storage and transmission</li>
                            </Typography>
                        </section>

                        <section>
                            <Typography variant="h3">User Rights</Typography>
                            <Typography>
                                You have the right to:
                            </Typography>
                            <Typography component="ul" sx={{ pl: 4 }}>
                                <li>Access and update your personal information</li>
                                <li>Request data deletion</li>
                                <li>Object to data processing</li>
                                <li>Withdraw consent</li>
                                <li>Lodge complaints with regulatory authorities</li>
                            </Typography>
                        </section>

                        <section>
                            <Typography variant="h3">International Transfers</Typography>
                            <Typography>
                                Your data may be transferred to and processed in countries outside your jurisdiction. We ensure adequate protection through:
                            </Typography>
                            <Typography component="ul" sx={{ pl: 4 }}>
                                <li>Standard contractual clauses</li>
                                <li>Adequacy decisions</li>
                                <li>Binding corporate rules</li>
                            </Typography>
                        </section>

                        <section>
                            <Typography variant="h3">Children's Privacy</Typography>
                            <Typography>
                                Our Service is not intended for users under 18. We do not knowingly collect data from children without parental consent.
                            </Typography>
                        </section>

                        <section>
                            <Typography variant="h3">Policy Changes</Typography>
                            <Typography>
                                We may update this policy and will notify you of significant changes. Continued use of our Service constitutes acceptance of the updated policy.
                            </Typography>
                        </section>

                        <section>
                            <Typography variant="h3">Contact Us</Typography>
                            <Typography>
                                For privacy-related inquiries, contact us at:
                            </Typography>
                            <Typography component="ul" sx={{ pl: 4 }}>
                                <li>Email: <a href="mailto:support@awesomeinnovators.com" style={{ color: '#D9376E' }}>support@awesomeinnovators.com</a></li>
                                <li>Phone: +91-87651 40002</li>
                                <li>Address: Raj Offset, Jamui Bazaar, Chunar, Mirzapur, Uttar Pradesh, India - 231304</li>
                            </Typography>
                        </section>

                        <Typography variant="body2" sx={{ 
                            mt: 4,
                            color: '#666',
                            fontStyle: 'italic'
                        }}>
                            Last updated: {new Date().toLocaleDateString()}
                        </Typography>
                    </Box>
                </Paper>
            </Container>
            </Box>
    )
}

export default PrivacyPolicy