import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchReportIssueRequest = () => ({
    type: actionTypes.REPORT_ISSUE_REQUEST,
});

export const fetchReportIssueSuccess = (data) => ({
    type: actionTypes.REPORT_ISSUE_SUCCESS,
    payload: data,
});

export const fetchReportIssueFailure = (error) => ({
    type: actionTypes.REPORT_ISSUE_FAILURE,
    payload: error,
});

export const fetchReportIssueData = (formData) => {
    return async (dispatch) => {
        dispatch(fetchReportIssueRequest());
        try {
            const {data} = await api.reportIssue(formData);
            dispatch(fetchReportIssueSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchReportIssueFailure());
        }
    };
};

export const createReportIssueRequest = () => ({
    type: actionTypes.CREATE_REPORT_ISSUE_REQUEST,
});

export const createReportIssueSuccess = (data) => ({
    type: actionTypes.CREATE_REPORT_ISSUE_SUCCESS,
    payload: data,
});

export const createReportIssueFailure = (error) => ({
    type: actionTypes.CREATE_REPORT_ISSUE_FAILURE,
    payload: error,
});

export const createReportIssueData = (newData) => {
    return async (dispatch) => {
        dispatch(createReportIssueRequest());
        try {
            const {data} = await api.createReportIssue(newData);
            console.log("data", data);
            dispatch(createReportIssueSuccess(data));
            return data;
        }catch (error) {
            dispatch(createReportIssueFailure(error));
        }
    };
};

export const createSupportTicketData = async (formData) => {
    const data = await api.createSupportTicket(formData);
    return data;
};

export const getAllSupportTickets = async () => {
    const data = await api.getSupportTickets();
    return data;
};

export const openInteractions = async (id) => {
    const data = await api.openItraction(id);
    return data;
};

export const createInteractions = async (formData) => {
    const data = await api.createItraction(formData?.id, formData);
    return data;
}