import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, } from 'react-redux'
import { Box, Button, Grid, MenuItem, Typography, TextField, FormControl, Select, InputLabel } from '@mui/material';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllDigitalCardData, getSingleDigitalCardData, updateDigitalInvitationCardData } from '../../redux/actions/digitalCardActions';
import Loading from '../../components/Loading';

function EditDigitalCard() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [cardDetails, setCardDetails] = useState(null);
    const params = useParams();
    console.log("params", params?.id);

    const [isFormValid, setIsFormValid] = useState(false);


    const validateForm = useCallback(() => {
        console.log("validating form");
        const allFieldsFilled = cardDetails?.card_fields?.every(field => field?.field_value);
        console.log("All fields filled", allFieldsFilled);
        setIsFormValid(allFieldsFilled); // Set based on actual validation result
    }, [cardDetails]);



    useEffect(() => {
        const fetchPartnerCardsData = async () => {
            setLoading(true);
            const cardData = await dispatch(getSingleDigitalCardData(params?.id));
            if (cardData) {
                setCardDetails(cardData?.data?.data);
            }
            await dispatch(getAllDigitalCardData());
            setLoading(false);
            validateForm(); // Safely called
        };
        fetchPartnerCardsData();
    }, [dispatch]); // Include validateForm in dependencies







    const handleFieldChange = (targetField, event) => {
        console.log("target field: ", targetField);
        console.log("target event value: ", event.target.value);
        console.log("card fields", cardDetails);
        setCardDetails(previousCardValues => {

            const updatedFields = previousCardValues?.card_fields.map(cardField => {
                if (cardField?.field_name === targetField?.field_name) {
                    let newValue = event.target.value;

                    // Validation
                    switch (cardField?.field_type) {
                        case 'string':
                            if (cardField?.field_validation === 'STRING_80') {
                                if (newValue.length > 80) {
                                    console.error("String value is too long");
                                    newValue = newValue.substring(0, 80); // STRING_80 validation
                                }
                            }
                            break;
                        
                        case 'number':
                            if (isNaN(newValue)) {
                                console.error("Value is not a number");
                                newValue = '';
                            } else {
                                newValue = Number(newValue);
                            }
                            break;
                        case 'email':
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            if (!emailRegex.test(newValue)) {
                                console.error("Invalid email format");
                                newValue = '';
                            }
                            break;
                        case 'date':
                            const dateValue = new Date(newValue);
                            if (isNaN(dateValue)) {
                                console.error("Invalid date format");
                                newValue = '';
                            } else {
                                newValue = formatDate(dateValue);
                            }
                            break;
                        case 'time':
                            const timeParts = newValue.split(':');
                            let hours = parseInt(timeParts[0], 10);
                            const minutes = timeParts[1];
                            const ampm = hours >= 12 ? 'PM' : 'AM';
                            hours = hours % 12 || 12;
                            newValue = `${hours}:${minutes} ${ampm}`;
                            break;
                        case 'file':
                            newValue = handleFileAndReturnUrlUpload(cardField, event);
                            break;
                        default:
                            break;
                    }

                    return { ...cardField, field_value: newValue };
                }
                return cardField;
            });
            console.log("updated fields", updatedFields);
            return { ...previousCardValues, card_fields: updatedFields };
        });
        validateForm();
    };

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };

    const handleFileAndReturnUrlUpload = async (event) => {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);

        try {
            const response = await axios.post('https://account.rajoffset.com/web/upload/v1/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            //setMessage(`File uploaded successfully: ${response.data.data}`);
            return response.data.data;

        } catch (error) {
            // setMessage(`File upload failed: ${error.message}`);
            return '';
        }
    };

    const handleEditSubmit = async () => {
        let body = {
            card_fields: cardDetails?.card_fields.map(({ field_name, id, field_value }) => ({
                field_name,
                id,
                field_value: field_value || ""
            })),
            id: params?.id,

        };
        console.log("Body", body);

        try {
            let rsp = await updateDigitalInvitationCardData(body);
            console.log("RSP", rsp);

            if (rsp.status === "success") {
                navigate(`/dashboard/digital-cards`);
            } else {
                console.error("Edit failed:", rsp.message);
            }
        } catch (error) {
            console.error("Error during submission:", error);
        }
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                <Typography variant='h4'>
                    Edit Digital Card
                </Typography>
            </Box>
            {loading ? (
                <Loading/>
            ) : (
                <Grid item xs={12} md={5} sx={{ padding: 2, borderRadius: '8px', backgroundColor: '#E2EAF4', marginLeft: { md: 30 }, marginRight: { md: 30 }, marginTop: 5 }}>
                    {cardDetails && cardDetails?.card_fields && cardDetails?.card_fields?.length > 0 ? (
                        <>

                            {cardDetails?.card_fields?.map((field, index) => (

                                <Box
                                    key={index}
                                    sx={{
                                        backgroundColor: '#eceff1',
                                        padding: 2,
                                        color: 'primary'
                                    }}
                                >
                                    {
                                        field.field_type === 'string' ?
                                            <TextField
                                                fullWidth
                                                name={field?.field_name}
                                                id={field?.id}
                                                label={field?.field_placeholder}
                                                placeholder={field?.field_placeholder}
                                                type={field?.field_type}
                                                defaultValue={field.field_default}
                                                required={field.field_required}
                                                value={field.field_value || ''}
                                                onChange={event => handleFieldChange(field, event)}
                                                InputLabelProps={{ shrink: true }}
                                                autoFocus
                                            ></TextField> : null
                                    }

                                    {
                                        field.field_type === 'file' ?
                                            <TextField
                                                fullWidth
                                                name={field?.field_name}
                                                id={field?.id}
                                                label={field?.field_placeholder}
                                                placeholder={field?.field_placeholder}
                                                type={field?.field_type}
                                                defaultValue={field.field_default}
                                                value={field.field_value || ''}
                                                required={field.field_required}
                                                onChange={event => handleFieldChange(field, event)}
                                                autoFocus
                                                InputLabelProps={{ shrink: true }}
                                            ></TextField> : null
                                    }

                                    {
                                        field.field_type === 'date' ?
                                            <TextField
                                                fullWidth
                                                name={field?.field_name}
                                                id={field?.id}
                                                label={field?.field_placeholder}
                                                placeholder={field?.field_placeholder}
                                                type={field?.field_type}
                                                defaultValue={field.field_default}
                                                value={field.field_value || ''}
                                                required={field.field_required}
                                                onChange={event => handleFieldChange(field, event)}
                                                InputLabelProps={{ shrink: true }}
                                                autoFocus
                                            ></TextField> : null
                                    }
                                    {
                                        field.field_type === 'time' ?
                                            <TextField
                                                fullWidth
                                                name={field?.field_name}
                                                id={field?.id}
                                                label={field?.field_placeholder}
                                                placeholder={field?.field_placeholder}
                                                type={field?.field_type}
                                                defaultValue={field.field_default}
                                                value={field.field_value || ''}
                                                required={field.field_required}
                                                onChange={event => handleFieldChange(field, event)}
                                                InputLabelProps={{ shrink: true }}
                                                autoFocus
                                            ></TextField> : null
                                    }
                                    {
                                        field.field_type === 'option' ? (
                                            <FormControl fullWidth required={field.field_required}>
                                                <InputLabel id={`label-${field.id}`}>{field?.field_placeholder}</InputLabel>
                                                <Select
                                                    labelId={`label-${field.id}`}
                                                    name={field?.field_name}
                                                    id={field?.id}
                                                    defaultValue={field?.field_default || 'xyz'}
                                                    onChange={event => handleFieldChange(field, event)}
                                                >
                                                    {field?.field_options.map(option => (
                                                        <MenuItem key={option?.id} value={option?.option_value}>
                                                            {option?.option_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : null
                                    }

                                    {
                                        field.field_type === 'number' ?
                                            <TextField
                                                fullWidth
                                                name={field?.field_name}
                                                id={field?.id}
                                                label={field?.field_placeholder}
                                                placeholder={field?.field_placeholder}
                                                type={field?.field_type}
                                                defaultValue={field.field_default}
                                                required={field.field_required}
                                                value={field.field_value || ''}
                                                onChange={event => handleFieldChange(field, event)}
                                                InputLabelProps={{ shrink: true }}
                                                autoFocus
                                            ></TextField> : null
                                    }

                                </Box>
                            ))}
                            <Box
                                sx={{
                                    backgroundColor: '#eceff1',
                                    padding: 2,
                                    color: 'primary',

                                }}
                            >


                            </Box>


                            <Button
                                variant="contained"
                                color="error"
                                sx={{ marginTop: 2, width: '100%' }}
                                onClick={() => handleEditSubmit(cardDetails)}
                                disabled={!isFormValid}
                            >
                                Submit
                            </Button>




                        </>
                    ) : (
                        <Typography>This card is restricted. To get this card whatsapp us on 876514002 or email us at support@awesomeinnovators.com</Typography>
                    )}


                </Grid>
            )}

        </div>
    )
}

export default EditDigitalCard