import React, { useEffect, useMemo } from 'react'
import { Box, Card, CardMedia, CardContent, Typography, Rating, Grid, Pagination, CircularProgress, Button, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { getAllCardsByGroupNameAndGroupType } from '../../redux/actions/cardsForCustomerActions';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import 'animate.css';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Loading from '../../components/Loading';
import { fetchAllCardGroupData } from '../../redux/actions/cardGroupActions';
import config from '../../../src/config/config';


function InvitationCardMaster() {


    const params = useParams();
    console.log("Params", params);
    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
    }, []);




    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 50;

    // useEffect(() => {
    //     console.log("params.group_path", params.group_path);
    //     // dispatch(getAllCardsByGroupNameAndGroupType(params.group_path, "pdf", page, limit));
    //     getAllCardsByGroupNameAndGroupType(params.group_path, "pdf", page, limit);
    // }, [page]);

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        // await setPage(value);
        // let rsp = await dispatch(getAllCardsByGroupNameAndGroupType(params.group_path, "pdf", page, value));
    };
    const [loading, setLoading] = useState(true);
    const [invitationCards, setInvitationcards] = useState([]);

    var cards = useSelector((state) => state?.cardforCustomer?.cardForCustomerdata?.data);
    const cards_original = useSelector((state) => state?.cardforCustomer?.cardForCustomerdata?.data);


    useEffect(() => {
        if (cards) {
            console.log("Cards", cards);
            setInvitationcards(cards.rows);
        }
    }, [cards]);

    const [selectedButton, setSelectedButton] = useState("video");

    const handleGroupTypeButtonClick = async (value) => {
        setLoading(true);
        setSelectedButton(value);
        await dispatch(getAllCardsByGroupNameAndGroupType(params.group_path, value, page, limit));
        setLoading(false);
    }

    const [cardGroup, setCardGroup] = useState();
    useEffect(() => {
        const fetchCardForCustomer = async () => {
            setLoading(true);
            let rsp = await dispatch(getAllCardsByGroupNameAndGroupType(params.group_path, selectedButton, page, limit));
            console.log("RSP", rsp);

            let total = rsp?.data?.total;
            let cards = rsp?.data?.rows;

            setInvitationcards(cards);

            let pages = Math.ceil(total / Number(limit));
            if (pages) {
                setTotalPages(pages);
            }

            const data = await dispatch(fetchAllCardGroupData());
            setCardGroup(data);

            setLoading(false);

        };

        fetchCardForCustomer();

    }, [dispatch, params.group_path]);


    // useEffect(() => {
    //     if (invitationCards.length === 0) {
    //         handleGroupTypeButtonClick('pdf');
    //     }
    // }, [invitationCards]);


    console.log("card group", cardGroup);

    const cardTypeAvailable = useMemo(() => {
        return cardGroup?.data?.data
            ?.filter((item) => item.group_path === params.group_path)
            .map((item) => item.group_type);
    }, [cardGroup, params.group_path]); 
    
    console.log("Available", cardTypeAvailable);
    
    useEffect(() => {
        if (cardTypeAvailable?.includes('video')) {
            handleGroupTypeButtonClick('video');
        } else {
            handleGroupTypeButtonClick('pdf');
        }
    }, [cardTypeAvailable]);
    

    const handleNavigation = (card_id) => {
        navigate(`/cards/${params?.group_path}/${selectedButton}/${card_id}`);
    };

    // Animation
    const [hover, setHover] = useState(null);

    const handleMouseEnter = (index) => {
        setHover(index);
    };

    const handleMouseLeave = () => {
        setHover(null);
    };

    // button group for fetching data 

    const [SelectFreeButton, setSelectFreeButton] = useState("0");

    const handleFreeTypeButtonClick = async () => {
        console.log("step 1", SelectFreeButton);
        await setSelectFreeButton(prev => (prev === true ? false : true));
        console.log("step 2", SelectFreeButton);
        if (SelectFreeButton) {
            console.log("inside if");
            const card = await cards_original?.rows?.filter((card) => card?.sale_price === 0);
            setInvitationcards(card);
        }
        else {
            console.log("else block", cards_original);
            const card = await cards_original?.rows?.filter((card) => card?.sale_price > 0);
            setInvitationcards(card);
        }

    }
    useEffect(() => {
        if (SelectFreeButton) {
            console.log("Price type", SelectFreeButton);
        }
    }, [SelectFreeButton])
    useEffect(() => {
        if (selectedButton) {
            console.log("Group type:", selectedButton);
            if (selectedButton === 'pdf') {
                // Specific condition action
                console.log("pdf");
            }
        }
    }, [selectedButton]);

    const [playingVideos, setPlayingVideos] = useState({});

    const handlePlayVideo = (index) => {
        setPlayingVideos((prevState) => ({
            ...prevState,
            [index]: true,
        }));
    };

    const [openCarouselSamples, setOpenCarouselSamples] = useState(null);
    return (
        <>
            <Grid item xs={12} sm={9} md={10} sx={{ padding: '32px' }}>
                <Typography variant="h5" sx={{ textAlign: 'left', fontWeight: 800 }}>
                    {params?.group_path?.charAt(0)?.toUpperCase() + params?.group_path?.slice(1)}
                </Typography>
                <Typography sx={{ marginBottom: '30px', textAlign: 'left' }}>
                    {selectedButton === "pdf" ? 'Invitation Cards' : 'Invitation Videos'}
                </Typography>

                <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                    {cardTypeAvailable?.includes('pdf') &&
                        <Button
                            variant='outlined'
                            sx={{
                                color: selectedButton === "pdf" ? '#424242' : '#616161',
                                borderColor: selectedButton === "pdf" ? '#bdbdbd' : '#e0e0e0',
                                backgroundColor: selectedButton === "pdf" ? '#bdbdbd' : '#eeeeee',
                                borderRadius: '20px',
                                textTransform: 'none',
                                marginRight: '8px',
                                padding: '6px 16px',
                                whiteSpace: 'nowrap',
                                minWidth: 'fit-content',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}
                            onClick={() => handleGroupTypeButtonClick("pdf")}
                        >
                            pdf
                        </Button>
                    }

                    {cardTypeAvailable?.includes('video') &&
                        <Button
                            variant='outlined'
                            sx={{
                                color: selectedButton === "video" ? '#424242' : '#616161',
                                borderColor: selectedButton === "video" ? '#bdbdbd' : '#e0e0e0',
                                backgroundColor: selectedButton === "video" ? '#bdbdbd' : '#eeeeee',
                                borderRadius: '20px',
                                textTransform: 'none',
                                marginRight: '8px',
                                padding: '6px 16px',
                                whiteSpace: 'nowrap',
                                minWidth: 'fit-content',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}
                            onClick={() => handleGroupTypeButtonClick("video")}
                        >
                            Video
                        </Button>
                    }
                    {cardTypeAvailable?.includes('digital invitation') &&
                        <Button
                            variant='outlined'
                            sx={{
                                color: selectedButton === "digital invitation" ? '#424242' : '#616161',
                                borderColor: selectedButton === "digital invitation" ? '#bdbdbd' : '#e0e0e0',
                                backgroundColor: selectedButton === "digital invitation" ? '#bdbdbd' : '#eeeeee',
                                borderRadius: '20px',
                                textTransform: 'none',
                                marginRight: '8px',
                                padding: '6px 16px',
                                whiteSpace: 'nowrap',
                                minWidth: 'fit-content',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}
                            onClick={() => handleGroupTypeButtonClick("digital invitation")}
                        >
                            digital invitation
                        </Button>
                    }
                    {cardTypeAvailable?.includes('multipage-pdf') &&
                        <Button
                            variant='outlined'
                            sx={{
                                color: selectedButton === "multipage-pdf" ? '#424242' : '#616161',
                                borderColor: selectedButton === "multipage-pdf" ? '#bdbdbd' : '#e0e0e0',
                                backgroundColor: selectedButton === "multipage-pdf" ? '#bdbdbd' : '#eeeeee',
                                borderRadius: '20px',
                                textTransform: 'none',
                                marginRight: '8px',
                                padding: '6px 16px',
                                whiteSpace: 'nowrap',
                                minWidth: 'fit-content',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}
                            onClick={() => handleGroupTypeButtonClick("multipage-pdf")}
                        >
                            multipage-pdf
                        </Button>
                    }

                    <Button
                        variant='outlined'
                        sx={{
                            color: SelectFreeButton === "0" ? '#424242' : '#616161',
                            borderColor: SelectFreeButton === "0" ? '#bdbdbd' : '#e0e0e0',
                            backgroundColor: SelectFreeButton === "0" ? '#bdbdbd' : '#eeeeee',
                            borderRadius: '20px',
                            textTransform: 'none',
                            marginRight: '8px',
                            padding: '6px 16px',
                            whiteSpace: 'nowrap',
                            minWidth: 'fit-content',
                            '&:hover': {
                                backgroundColor: '#f0f0f0',
                            },
                        }}
                        onClick={handleFreeTypeButtonClick}
                    >
                        Free
                    </Button>

                </Box>





                {/* Show loader when loading */}
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                        <Loading />
                    </Box>
                ) : (
                    <>
                        {/* Cards Grid */}
                        <Grid container spacing={3}>
                            {invitationCards && invitationCards.length > 0 ? (
                                invitationCards.map((card, firstIndex) =>
                                    card.card_samples && card.card_samples.length > 0 ? (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={firstIndex}>
                                            <Card
                                                onClick={() => handleNavigation(card.id)}
                                                onMouseEnter={() => handleMouseEnter(firstIndex)}
                                                onMouseLeave={handleMouseLeave}
                                                className={hover === firstIndex ? 'animate__animated animate__pulse' : ''}
                                                sx={{ maxWidth: '100%', margin: 'auto' }}
                                            >
                                                <div style={{ position: 'relative' }}>
                                                    {(() => {
                                                        const type = card.card_samples[0]; // Only first sample
                                                        if (type?.url.endsWith(".webp") || type?.url.endsWith(".jpg") || type?.url.endsWith(".png")) {
                                                            return (
                                                                <>
                                                                    <CardMedia
                                                                        component="img"
                                                                        height="280"
                                                                        image={type.url}
                                                                        alt={type.url}
                                                                        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                                                    />
                                                                    <CardContent>
                                                                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: 1, marginBottom: { md: 1 } }}>
                                                                            <Box bgcolor="#d32f2f" color="white" px={0.5} py={0.5} borderRadius="4px" display="inline-block" sx={{ fontSize: '0.725rem' }}>
                                                                                {Math.round(((card?.original_price - card?.sale_price) / card?.original_price) * 100)}% off
                                                                            </Box>
                                                                            <Typography sx={{ fontSize: '0.825rem', color: 'red', fontWeight: 'bold' }}>{config.CURRENT_SALE_NAME}</Typography>
                                                                        </Box>
                                                                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, flexDirection: { xs: 'column', md: 'row' } }}>
                                                                            <Typography variant="h6" color="textPrimary" fontWeight="bold" sx={{ fontSize: '1.25rem', lineHeight: 1 }}>
                                                                                {Math.floor(card.sale_price) === 0 ? 'Free' : `₹${Math.floor(card.sale_price)}`}
                                                                            </Typography>
                                                                            <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through', fontSize: '0.875rem' }}>
                                                                                M.R.P: ₹{card?.original_price.toLocaleString()}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Typography sx={{ fontSize: '1rem' }}>{card.card_name}</Typography>
                                                                        <Rating value={card.card_rating} readOnly />
                                                                    </CardContent>
                                                                    {card?.card_samples?.length > 1 && (
                                                                        <Box
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: '8px',
                                                                                right: '8px',
                                                                                backgroundColor: '#d32f2f',
                                                                                color: 'white',
                                                                                px: 0.5,
                                                                                py: 0.5,
                                                                                borderRadius: '4px',
                                                                                fontSize: '0.725rem',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                setOpenCarouselSamples(card.card_samples);

                                                                            }}
                                                                        >
                                                                            Multi-Page
                                                                        </Box>
                                                                    )}
                                                                </>
                                                            );
                                                        } else if (type?.url.endsWith(".mp4")) {
                                                            return (
                                                                <>
                                                                    {!playingVideos[firstIndex] ? (
                                                                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                                                            <CardMedia component="img" image={type.thumbnail} alt={card.card_name} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                                                                            <IconButton
                                                                                onClick={(event) => {
                                                                                    event.stopPropagation();
                                                                                    handlePlayVideo(firstIndex);
                                                                                }}
                                                                                style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#616161', fontSize: 64 }}
                                                                            >
                                                                                <PlayCircleOutlineIcon fontSize="inherit" />
                                                                            </IconButton>
                                                                        </div>
                                                                    ) : (
                                                                        <CardMedia component="video" controls autoPlay src={type.url} alt={type.thumbnail} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                                                                    )}
                                                                    <CardContent>
                                                                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: 1, marginBottom: { md: 1 } }}>
                                                                            <Box bgcolor="#d32f2f" color="white" px={0.5} py={0.5} borderRadius="4px" display="inline-block" sx={{ fontSize: '0.725rem' }}>
                                                                                {Math.round(((card?.original_price - card?.sale_price) / card?.original_price) * 100)}% off
                                                                            </Box>
                                                                            <Typography sx={{ fontSize: '0.825rem', color: 'red', fontWeight: 'bold' }}>Dev Diwali Sale</Typography>
                                                                        </Box>
                                                                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, flexDirection: { xs: 'column', md: 'row' } }}>
                                                                            <Typography variant="h6" color="textPrimary" fontWeight="bold" sx={{ fontSize: '1.25rem', lineHeight: 1 }}>
                                                                                {Math.floor(card.sale_price) === 0 ? 'Free' : `₹${Math.floor(card.sale_price)}`}
                                                                            </Typography>
                                                                            <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through', fontSize: '0.875rem' }}>
                                                                                M.R.P: ₹{card?.original_price.toLocaleString()}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Typography variant="h6" sx={{ fontWeight: 800, marginBottom: 1 }}>{card.card_name}</Typography>
                                                                        <Rating value={card.card_rating} readOnly />
                                                                    </CardContent>
                                                                </>
                                                            );
                                                        }
                                                    })()}
                                                </div>
                                            </Card>
                                        </Grid>
                                    ) : (
                                        <Typography key={firstIndex}>Please wait while we are fetching card details...</Typography>
                                    )
                                )
                            ) : (
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            minHeight: '60vh',
                                            py: 4,
                                            px: 2,
                                            background: theme => theme.palette.mode === 'dark' 
                                                ? 'linear-gradient(180deg, rgba(30, 30, 60, 0.1) 0%, rgba(30, 30, 60, 0.2) 100%)'
                                                : 'linear-gradient(180deg, rgba(245, 247, 250, 0.5) 0%, rgba(245, 247, 250, 1) 100%)',
                                            borderRadius: 4
                                        }}
                                    >
                                        <Box 
                                            component="img"
                                            src="https://cdn-icons-png.flaticon.com/512/7486/7486754.png"
                                            alt="No Data"
                                            sx={{
                                                width: '200px',
                                                height: '200px',
                                                opacity: 0.8,
                                                mb: 4,
                                                animation: 'float 3s ease-in-out infinite',
                                                '@keyframes float': {
                                                    '0%, 100%': {
                                                        transform: 'translateY(0)',
                                                    },
                                                    '50%': {
                                                        transform: 'translateY(-10px)',
                                                    },
                                                },
                                            }}
                                        />

                                        <Typography 
                                            variant="h4" 
                                            sx={{
                                                mb: 2,
                                                fontWeight: 600,
                                                textAlign: 'center',
                                                color: theme => theme.palette.mode === 'dark' ? '#fff' : '#333',
                                                fontSize: { xs: '1.5rem', sm: '2rem' }
                                            }}
                                        >
                                            No Designs Available Yet
                                        </Typography>

                                        <Typography 
                                            variant="h5" 
                                            sx={{
                                                mb: 3,
                                                fontWeight: 500,
                                                textAlign: 'center',
                                                color: '#d32f2f',
                                                fontSize: { xs: '1.2rem', sm: '1.5rem' }
                                            }}
                                        >
                                            अभी कोई डिज़ाइन उपलब्ध नहीं है
                                        </Typography>

                                        <Typography 
                                            variant="body1" 
                                            sx={{
                                                mb: 1,
                                                textAlign: 'center',
                                                color: theme => theme.palette.mode === 'dark' 
                                                    ? 'rgba(255, 255, 255, 0.7)' 
                                                    : 'rgba(0, 0, 0, 0.7)',
                                                maxWidth: '600px',
                                                fontSize: { xs: '0.9rem', sm: '1rem' }
                                            }}
                                        >
                                            We're working on adding new and beautiful designs to this category.
                                            Please check back later or explore other categories.
                                        </Typography>

                                        <Typography 
                                            variant="body1" 
                                            sx={{
                                                mb: 4,
                                                textAlign: 'center',
                                                color: theme => theme.palette.mode === 'dark' 
                                                    ? 'rgba(255, 255, 255, 0.7)' 
                                                    : 'rgba(0, 0, 0, 0.7)',
                                                maxWidth: '600px',
                                                fontSize: { xs: '0.9rem', sm: '1rem' }
                                            }}
                                        >
                                            हम इस श्रेणी में नए और सुंदर डिज़ाइन जोड़ने पर काम कर रहे हैं।
                                            कृपया बाद में दोबारा देखें या अन्य श्रेणियों को देखें।
                                        </Typography>

                                        <Box 
                                            sx={{ 
                                                display: 'flex', 
                                                gap: 1.5, 
                                                flexWrap: 'wrap',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            {cardTypeAvailable?.map((type) => (
                                                <Button
                                                    key={type}
                                                    variant='outlined'
                                                    sx={{
                                                        color: selectedButton === type ? '#424242' : '#616161',
                                                        borderColor: selectedButton === type ? '#bdbdbd' : '#e0e0e0',
                                                        backgroundColor: selectedButton === type ? '#bdbdbd' : '#eeeeee',
                                                        borderRadius: '20px',
                                                        textTransform: 'none',
                                                        padding: '6px 16px',
                                                        whiteSpace: 'nowrap',
                                                        minWidth: 'fit-content',
                                                        '&:hover': {
                                                            backgroundColor: '#f0f0f0',
                                                        },
                                                    }}
                                                    onClick={() => handleGroupTypeButtonClick(type)}
                                                >
                                                    {type.split('-').map(word => 
                                                        word.charAt(0).toUpperCase() + word.slice(1)
                                                    ).join(' ')} Invitations
                                                </Button>
                                            ))}
                                        </Box>
                                    </Box>
                                </Grid>
                            )}

                        </Grid>


                        {/* Pagination */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                            <Pagination
                                count={totalPages}
                                page={page}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    </>
                )}
            </Grid>
        </>
    )
}

export default InvitationCardMaster