import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, List, ListItemButton, ListItemText, Box, MenuItem, Button, LinearProgress, CircularProgress, Dialog, DialogContent, Typography, Divider } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StyledMenu from './StyledMenu';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme as useAppTheme } from '../theme/ThemeContext';
import { Logout } from '../redux/actions/authActions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchAllCardGroupData } from '../redux/actions/cardGroupActions';

const DrawerComp = ({ openMenu, handleMenuClick, handleClose, menuStates, buttonLabels, menuItems }) => {
    const [open, setOpen] = useState(false);
    const [cardGroups, setCardGroups] = useState([]);
    
    console.log("menu item in drawer", menuItems);

    const handleDrawerToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleMenuClose = () => {
        handleClose();
        // Ensure drawer remains open when menu is closed
    };

    const handleLogin = () => {
        setOpen(false);
        navigate('/login');
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Fetch card groups when component mounts
    useEffect(() => {
        const fetchCardGroups = async () => {
            try {
                const data = await dispatch(fetchAllCardGroupData());
                setCardGroups(data?.data?.data || []);
            } catch (error) {
                console.error("Error fetching card groups:", error);
            }
        };
        
        fetchCardGroups();
    }, [dispatch]);

    const handleNavigation = (path_url) => {
        navigate(`/cards/${path_url}`);
        setOpen(false);
    };
    
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const { isDarkMode, } = useAppTheme();
    const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] = useState(null);

    const handleAvatarMenuClose = () => {
        setAvatarMenuAnchorEl(null);
    };

    const [logoutOpen, setLogoutOpen] = useState(false);

    const handleLogout = async () => {
        setLogoutOpen(true);
        toast.info("Please wait, logging out...");

        try {
            setTimeout(async () => {
                await dispatch(Logout());
                setLogoutOpen(false);
            }, 10000);
        } catch (error) {
            console.error("Logout failed:", error);
            setLogoutOpen(false);
        }
    };

    return (
        <>
            <Drawer 
                anchor="right" 
                open={open} 
                onClose={() => setOpen(false)}
            >
                <Box sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    width: 250
                }}>
                    {/* Scrollable content area */}
                    <Box sx={{ 
                        flex: '1 1 auto', 
                        overflowY: 'auto',
                        pb: 2 // Add bottom padding to prevent content from being hidden behind the footer
                    }}>
                        <List>
                            {buttonLabels.map((label, index) => (
                                <ListItemButton key={index} onClick={(e) => handleMenuClick(e, index + 1)}>
                                    <ListItemText primary={label} />
                                    <KeyboardArrowDownIcon />
                                    <StyledMenu
                                        id={`drawer-customized-menu-${index + 1}`}
                                        anchorEl={menuStates[index].anchorEl}
                                        open={openMenu && menuStates[index].isOpen}
                                        onClose={handleMenuClose}
                                    >
                                        {menuItems[index + 1].map((item, itemIndex) => (
                                            <MenuItem key={itemIndex} onClick={() => handleNavigation(item.path)} disableRipple>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </StyledMenu>
                                </ListItemButton>
                            ))}
                        </List>
                        
                        {/* Card Groups Section */}
                        <Box sx={{ mt: 2, px: 2 }}>
                            <Typography 
                                variant="subtitle1" 
                                sx={{ 
                                    fontWeight: 600, 
                                    mb: 1, 
                                    color: isDarkMode ? '#BB86FC' : '#1976d2' 
                                }}
                            >
                                Card Categories
                            </Typography>
                            <Divider />
                            <List sx={{ py: 1 }}>
                                {cardGroups.map((group, index) => (
                                    <ListItemButton 
                                        key={index}
                                        onClick={() => handleNavigation(group.group_path)}
                                        sx={{ 
                                            py: 0.75,
                                            borderRadius: '4px',
                                            mb: 0.5,
                                            '&:hover': {
                                                backgroundColor: isDarkMode ? 'rgba(187, 134, 252, 0.08)' : 'rgba(25, 118, 210, 0.08)'
                                            }
                                        }}
                                    >
                                        <ListItemText 
                                            primary={group.group_name} 
                                            primaryTypographyProps={{ 
                                                fontSize: '0.9rem',
                                                fontWeight: 500
                                            }}
                                        />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Box>
                    </Box>
                    
                    {/* Fixed footer section */}
                    <Box
                        sx={{
                            flex: '0 0 auto',
                            width: '100%',
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderTop: `1px solid ${isDarkMode ? 'rgba(255,255,255,0.12)' : 'rgba(0,0,0,0.12)'}`,
                            backgroundColor: isDarkMode ? '#121212' : '#ffffff',
                            zIndex: 1,
                        }}
                    >
                        {!isAuthenticated ? (
                            <Button
                                sx={{
                                    textTransform: 'none',
                                    color: isDarkMode ? '#BB86FC' : 'black'
                                }}
                                onClick={handleLogin}
                            >
                                Login/Register
                            </Button>
                        ) : (
                            <Button
                                sx={{
                                    textTransform: 'none',
                                    color: isDarkMode ? '#BB86FC' : 'black'
                                }}
                                onClick={handleLogout}
                            >
                                Logout
                            </Button>
                        )}
                    </Box>
                </Box>
            </Drawer>

            <IconButton sx={{ marginLeft: 'auto' }} onClick={handleDrawerToggle}>
                <MenuRoundedIcon />
            </IconButton>
            
            <Dialog
                open={logoutOpen}
                PaperProps={{
                    style: {
                        borderRadius: "12px",
                        padding: "20px",
                        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        backdropFilter: "blur(4px)",
                    },
                }}
            >
                <DialogContent
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        gap: "20px",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#e3f2fd",
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress size={30} color="primary" />
                    </div>

                    <Typography variant="h6" style={{ fontWeight: "bold", color: "#424242" }}>
                        Logging out, please wait...
                    </Typography>

                    <LinearProgress
                        style={{
                            width: "100%",
                            height: "8px",
                            borderRadius: "4px",
                            backgroundColor: "#e0e0e0",
                        }}
                        color="primary"
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DrawerComp;
