import React, { useEffect } from 'react';
import { Grid, Box, Typography, Container, IconButton, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import packageJson from '../../package.json';
import { Facebook, Twitter, Instagram } from '@mui/icons-material';

function Footer() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const appVersion = packageJson.version;
  const navigate = useNavigate();

  const menuItems = [
    { label: 'FAQ', path: '/faq' },
    { label: 'About Us', path: '/about-us' },
    { label: 'Contact Us', path: '/contact-us' },
    { label: 'Terms & Conditions', path: '/terms-and-conditions' },
    { label: 'Privacy Policy', path: '/privacy-policy' },
    { label: 'Refund Policy', path: '/refund-policy' },
    { label: 'Delete Account Policy', path: '/delete-account-policy' },
    { label: 'Support Ticket', path: '/my-ticket' },
  ];

  const invitationCategory = [
    { label: 'Engagement Invitation', path: 'engagement' },
    { label: 'Wedding Invitation', path: 'wedding' },
    { label: 'House Warming Invitation', path: 'housewarming' },
    { label: 'Shop Grand Opening', path: 'shop-opening' },
    { label: 'Mundan Invitation', path: 'mundan' },
    { label: 'Naming Ceremony', path: 'naming-ceremony' },
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleInvitationCardNavigation = (path_url) => {
    navigate(`/cards/${path_url}`);
  };

  useEffect(() => {
    if('scrollRestoration' in window.history){
      window.history.scrollRestoration = 'manual';
    }
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    handleScrollToTop();

    return () => {
      if( 'scrollRestoration' in window.history){
        window.history.scrollRestoration = 'auto';
      }
    };
  }, []);

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#f5f5f5',
        color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'text.primary',
        borderTop: `1px solid ${isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#e0e0e0'}`,
        mt: 'auto',
        py: 6,
        px: 2
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* About Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" sx={{ 
              fontWeight: 700,
              mb: 2,
              color: isDarkMode ? 'primary.main' : 'primary.dark'
            }}>
              RajOffset
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              RajOffset is your go-to platform for creating stunning, personalized invitations. 
              From weddings to special events, we offer easy-to-use tools and beautiful templates 
              to help you design invitations that truly reflect your unique style. Create memorable 
              invitations for any occasion with RajOffset.
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton 
                color="inherit" 
                onClick={() => window.open('https://facebook.com', '_blank')}
              >
                <Facebook />
              </IconButton>
              <IconButton 
                color="inherit" 
                onClick={() => window.open('https://twitter.com', '_blank')}
              >
                <Twitter />
              </IconButton>
              <IconButton 
                color="inherit" 
                onClick={() => window.open('https://instagram.com', '_blank')}
              >
                <Instagram />
              </IconButton>
            </Box>
          </Grid>

          {/* Quick Links Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" sx={{ 
              fontWeight: 700,
              mb: 2,
              color: isDarkMode ? 'primary.main' : 'primary.dark'
            }}>
              Quick Links
            </Typography>
            <Grid container spacing={1}>
              {menuItems.map((item, index) => (
                <Grid item xs={6} key={index}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: 'inherit',
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'primary.main',
                        textDecoration: 'underline'
                      }
                    }}
                    onClick={() => handleNavigation(item.path)}
                  >
                    {item.label}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Partners Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" sx={{ 
              fontWeight: 700,
              mb: 2,
              color: isDarkMode ? 'primary.main' : 'primary.dark'
            }}>
              Partners
            </Typography>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => window.open('https://partners.rajoffset.com/', '_blank')}
              sx={{
                textTransform: 'none',
                borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'text.primary',
                color: 'inherit',
                '&:hover': {
                  backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
                  borderColor: 'primary.main'
                }
              }}
            >
              Visit Partners Portal
            </Button>
          </Grid>
        </Grid>

        {/* Copyright Section */}
        <Box
          sx={{
            borderTop: `1px solid ${isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#e0e0e0'}`,
            mt: 4,
            pt: 3,
            textAlign: 'center'
          }}
        >
          <Typography variant="body2" color="textSecondary">
            © 2017 - {new Date().getFullYear()} RajOffset. All rights reserved.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Version {appVersion} - Powered by Awesome Innovators
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Designed and Developed in 🇮🇳 with ❤️
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
