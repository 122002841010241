import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Card,
    CardContent,
    Stack,
    Button,
    useTheme,
    useMediaQuery,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import {
    CurrencyRupee,
    CheckCircle,
    AccessTime,
    LocalOffer,
    Receipt,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { initiateSubscription } from '../../utils/razorpayUtils';
import { toast } from 'react-toastify';
import config from '../../config/config';
import { useDispatch } from 'react-redux';
import { getSubscriptionAmountData } from '../../redux/actions/SubcriptionAmountActions';
import { useSelector } from 'react-redux';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const NetajiSubscription = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDarkMode = theme.palette.mode === 'dark';

    const subscriptionFeatures = [
        "Daily 2 Good Morning Image Designs",
        "Daily 1 Good Morning Video",
        "Festival Poster (त्यौहार पोस्टर बैनर)",
        "Birthday Poster (जन्मदिन पोस्टर)",
        "Party Poster (पार्टी पोस्टर)",
        "Jayanti / Diwas (जयन्ती / दिवस पोस्टर बैनर)",
        "God / Devotional Post (भगवान/भक्ति पोस्टर बैनर)",
        "Other Political Poster (अन्य राजनीतिक पोस्टर)",
        "Congratulations Post (शुभकामना सन्देश पोस्टर)",
        "Custom Poster (कस्टम पोस्टर)",
        "AI Supported Poster (एआई पोस्टर)",
        "AI Supported Video (एआई वीडियो)",
        "Self made Campaign Poster (स्वयं बनाया गया कैंपेन पोस्टर)",
        "Supporter Post Design (सपोर्टर पोस्टर)",
        "Supporter Video Design (सपोर्टर वीडियो)",
    ];

    const subscriptionDetails = {
        basePrice: 99,
        gst: 18,
        totalPrice: 117,
    };

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const params = new URLSearchParams(window.location.search);
    const entryId = params.get("entryId");
    console.log("entry Id", entryId);
    const subscriptionData = useSelector((state) => state?.subscriptionAmount?.subscriptionAmountdata?.data?.data?.subscription_detail);
    const tL = useSelector((state) => state?.subscriptionAmount?.subscriptionAmountdata?.data?.data?.timeLeft);
    console.log("subcription details", subscriptionData);

    const [timeLeft, setTimeLeft] = useState(subscriptionData?.special_price_time);
    const [currentAmount, setCurrentAmount] = useState(subscriptionData?.special_price);
    const [gstAmount, setGstAmount] = useState(Math.ceil(subscriptionData?.special_price * 0.18));

    // useEffect(() => {
    //     if (timeLeft > 0) {
    //         const timer = setInterval(() => {
    //             setTimeLeft((prevTime) => prevTime - 1);
    //         }, 1000);
    //         return () => clearInterval(timer);
    //     } else {
    //         setCurrentAmount(amount?.sale_price);
    //         setGstAmount(Math.ceil(amount?.sale_price * 0.18));
    //     }

    // }, [timeLeft, amount?.sale_price]);


    // useEffect(() => {
    //     let timer;
    //     if (subscriptionData?.special_price && subscriptionData?.special_price !== "0" && subscriptionData?.special_price_time) {
    //         // Convert seconds to milliseconds for the timer
    //         const seconds = parseInt(subscriptionData?.special_price_time);
    //         const endTime = new Date();
    //         endTime.setSeconds(endTime.getSeconds() + seconds); // Add the time in seconds

    //         timer = setInterval(() => {
    //             const now = new Date();
    //             const difference = endTime - now;

    //             if (difference > 0) {
    //                 const hours = Math.floor(difference / (1000 * 60 * 60));
    //                 const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    //                 const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    //                 setTimeLeft({
    //                     hours,
    //                     minutes,
    //                     seconds,
    //                 });
    //             } else {
    //                 clearInterval(timer);
    //                 setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
    //                 setCurrentAmount(subscriptionData?.sale_price);
    //                 setGstAmount(Math.ceil(subscriptionData?.sale_price * 0.18));
    //             }
    //         }, 1000);
    //     }

    //     return () => {
    //         if (timer) {
    //             clearInterval(timer);
    //         }
    //     };
    // }, [subscriptionData]);

    useEffect(() => {
        const fetchAmount = async () => {
            const data = await dispatch(getSubscriptionAmountData(entryId));
            // const data = await dispatch(getSubscriptionAmountData(sampleEntryId));

        }
        fetchAmount();
    }, [])


    useEffect(() => {
        let timer;
        if (tL) {
            const minutes = parseInt(tL);
            const endTime = new Date();
            endTime.setMinutes(endTime.getMinutes() + minutes);

            timer = setInterval(() => {
                const now = new Date();
                const difference = endTime - now;

                if (difference > 0) {
                    const hours = Math.floor(difference / (1000 * 60 * 60));
                    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                    setTimeLeft({
                        hours,
                        minutes,
                        seconds
                    });
                } else {
                    clearInterval(timer);
                    setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
                    setCurrentAmount(subscriptionData?.sale_price);
                    setGstAmount(Math.ceil(subscriptionData?.sale_price * 0.18));
                }
            }, 1000);
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [subscriptionData]);
    const totalAmount = (currentAmount + gstAmount);



    const handlePayment = async () => {
        try {

            const result = await initiateSubscription(entryId);

            // Navigate based on status
            switch (result.data.subscription_status) {
                case 'active':
                    // navigate('/subscription-success');
                    window.location.href = `${config.NETAJI_SUBSCRIPTION_PAYMENT_REDIRECT_URL}/subscription-success?entryId=${entryId}`
                    break;
                case 'pending':
                    //navigate('/subscription-pending');
                    window.location.href = `${config.NETAJI_SUBSCRIPTION_PAYMENT_REDIRECT_URL}/subscription-pending?entryId=${entryId}`
                    break;
                default:
                    // navigate('/my-profile');
                    window.location.href = `${config.NETAJI_SUBSCRIPTION_PAYMENT_REDIRECT_URL}/subscription-failed?entryId=${entryId}`
                    window.location.href = "https://netajiposter.com/subscription-failed"
            }
        } catch (error) {
            if (error.message === 'Subscription process cancelled') {
                toast.info('Subscription process cancelled');
                window.location.href = `${config.NETAJI_SUBSCRIPTION_PAYMENT_REDIRECT_URL}/subscription-pending?entryId=${entryId}`
            } else {
                window.location.href = `${config.NETAJI_SUBSCRIPTION_PAYMENT_REDIRECT_URL}/subscription-pending?entryId=${entryId}`
            }
        }
    };

    return (
        <Container
            maxWidth="lg"
            disableGutters={isMobile}
            sx={{
                px: { xs: 0, sm: 2 },
                py: { xs: 0, sm: 3 },
                minHeight: '100vh',
                background: isDarkMode
                    ? 'linear-gradient(135deg, rgba(40, 40, 80, 0.95), rgba(30, 30, 60, 0.85))'
                    : 'linear-gradient(135deg, rgba(248, 250, 255, 0.95), rgba(240, 245, 255, 0.85))',
            }}
        >
            {/* Header Section */}
            <Box
                sx={{
                    px: { xs: 2, sm: 3 },
                    py: { xs: 2, sm: 3 },
                    background: isDarkMode
                        ? 'linear-gradient(45deg, #1e88e5 10%, #1565c0 90%)'
                        : 'linear-gradient(45deg, #2196f3 10%, #1976d2 90%)',
                    borderRadius: { xs: 0, sm: 2 },
                    mb: 3,
                    position: 'relative',
                    overflow: 'hidden',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'radial-gradient(circle at top right, rgba(255,255,255,0.2) 0%, transparent 70%)',
                    },
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 700,
                        fontSize: { xs: '1.5rem', sm: '2rem' },
                        color: '#fff',
                        textAlign: 'center',
                        textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
                        letterSpacing: '0.5px',
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    Netaji Subscription
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        color: 'rgba(255, 255, 255, 0.9)',
                        textAlign: 'center',
                        mt: 1,
                        fontSize: { xs: '1rem', sm: '1.25rem' },
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    Premium Poster and Video Monthly Subscription
                </Typography>
            </Box>

            {/* Main Content */}
            <Stack spacing={3} sx={{ px: { xs: 2, sm: 0 } }}>
                {/* Price Card */}
                <Card
                    elevation={isDarkMode ? 2 : 1}
                    sx={{
                        borderRadius: { xs: 2, sm: 3 },
                        background: isDarkMode
                            ? 'linear-gradient(135deg, rgba(30, 136, 229, 0.15), rgba(21, 101, 192, 0.25))'
                            : 'linear-gradient(135deg, #ffffff, #f5f5f5)',
                        border: `1px solid ${isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`,
                    }}
                >
                    <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                        <Stack spacing={2}>
                            <Box sx={{ textAlign: 'center' }}>
                                {timeLeft?.seconds > 0 ? (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 1,
                                        color: 'error.main',
                                        mt: 1,
                                        mb: 2
                                    }}>
                                        <AccessTimeIcon />
                                        <Typography variant="body1" fontWeight="medium">
                                            Special offer ends in: {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
                                        </Typography>
                                    </Box>
                                ) : null}

                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontWeight: 700,
                                        color: isDarkMode ? '#90caf9' : '#1976d2',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 1,
                                    }}
                                >
                                    <CurrencyRupee sx={{ fontSize: 'inherit' }} />
                                    {timeLeft?.seconds > 0 ? currentAmount : subscriptionData?.sale_price}
                                    {timeLeft?.seconds > 0 && <Typography variant="body1"> + GST</Typography>}
                                </Typography>

                                {timeLeft?.seconds > 0 && (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'column', sm: 'row' },
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: { xs: 0.5, sm: 2 }
                                    }}>
                                        <Typography variant="h6" color="text.secondary" sx={{
                                            textDecoration: 'line-through',
                                            opacity: 0.7,
                                        }}>
                                            ₹{subscriptionData?.sale_price}
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary" sx={{
                                            textDecoration: 'line-through',
                                            opacity: 0.7,
                                        }}>
                                            ₹{subscriptionData?.original_price}
                                        </Typography>
                                    </Box>
                                )}

                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        color: 'text.secondary',
                                        mt: 1,
                                    }}
                                >

                                </Typography>
                            </Box>

                            <Divider />

                            {/* Price Breakdown */}
                            <Box sx={{
                                bgcolor: isDarkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.02)',
                                p: 2,
                                borderRadius: 1,
                            }}>
                                {timeLeft?.seconds ? (
                                    <Stack spacing={1}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography>Base Price</Typography>
                                            <Typography>₹{currentAmount}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography>GST ({subscriptionDetails?.gst}%)</Typography>
                                            <Typography>₹{(currentAmount * (subscriptionDetails?.gst / 100)).toFixed(2)}</Typography>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography sx={{ fontWeight: 600 }}>Total Amount</Typography>
                                            <Typography sx={{ fontWeight: 600 }}>
                                                ₹{(parseFloat(currentAmount) + parseFloat(currentAmount * (subscriptionDetails?.gst / 100))).toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                ) : (
                                    <Stack spacing={1}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography>Base Price</Typography>
                                            <Typography>₹{subscriptionData?.sale_price}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography>GST ({subscriptionDetails?.gst}%)</Typography>
                                            <Typography>₹{(subscriptionData?.sale_price * (subscriptionDetails?.gst / 100)).toFixed(2)}</Typography>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography sx={{ fontWeight: 600 }}>Total Amount</Typography>
                                            <Typography sx={{ fontWeight: 600 }}>
                                                ₹{(parseFloat(subscriptionData?.sale_price) + parseFloat(subscriptionData?.sale_price * (subscriptionDetails?.gst / 100))).toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                )}
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
                {/* Subscribe Button */}
                <Button
                    variant="contained"
                    size="large"
                    sx={{
                        animation: 'pulse 1s infinite',
                        textTransform: 'none',
                        px: 4,
                        backgroundColor: '#e53935',
                        '&:hover': {
                            backgroundColor: '#d32f2f'
                        },
                        py: 2,
                        borderRadius: 2,
                        boxShadow: '0 3px 5px 2px rgba(33, 150, 243, .3)',
                    }}
                    onClick={() => handlePayment()}
                >
                    Buy Now
                </Button>

                {/* Features Card */}
                <Card
                    elevation={isDarkMode ? 2 : 1}
                    sx={{
                        borderRadius: { xs: 2, sm: 3 },
                        background: isDarkMode
                            ? 'linear-gradient(135deg, rgba(30, 136, 229, 0.15), rgba(21, 101, 192, 0.25))'
                            : 'linear-gradient(135deg, #ffffff, #f5f5f5)',
                        border: `1px solid ${isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`,
                    }}
                >
                    <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                        <Typography
                            variant="h6"
                            sx={{
                                mb: 2,
                                color: isDarkMode ? '#90caf9' : '#1976d2',
                                fontWeight: 600,
                            }}
                        >
                            Monthly Subscription Features
                        </Typography>
                        <List>
                            {subscriptionFeatures.map((feature, index) => (
                                <ListItem key={index} sx={{ px: 0 }}>
                                    <ListItemIcon sx={{ minWidth: 36 }}>
                                        <CheckCircle sx={{ color: isDarkMode ? '#90caf9' : '#1976d2' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={feature} />
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>

                {/* Additional Information */}
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    sx={{ mt: 3 }}
                >
                    <Card
                        sx={{
                            flex: 1,
                            borderRadius: 2,
                            background: isDarkMode
                                ? 'linear-gradient(135deg, rgba(30, 136, 229, 0.15), rgba(21, 101, 192, 0.25))'
                                : 'linear-gradient(135deg, #ffffff, #f5f5f5)',
                        }}
                    >
                        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <AccessTime sx={{ color: isDarkMode ? '#90caf9' : '#1976d2' }} />
                            <Typography>Instant activation after payment</Typography>
                        </CardContent>
                    </Card>
                    <Card
                        sx={{
                            flex: 1,
                            borderRadius: 2,
                            background: isDarkMode
                                ? 'linear-gradient(135deg, rgba(30, 136, 229, 0.15), rgba(21, 101, 192, 0.25))'
                                : 'linear-gradient(135deg, #ffffff, #f5f5f5)',
                        }}
                    >
                        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Receipt sx={{ color: isDarkMode ? '#90caf9' : '#1976d2' }} />
                            <Typography>Premium Customer Support</Typography>
                        </CardContent>
                    </Card>
                </Stack>


            </Stack>
        </Container>
    );
};

export default NetajiSubscription; 