import React, { useEffect, useState } from 'react'
import { List, ListItem, ListItemText,  Grid,  ListItemButton, Divider,  } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAllSidebarItems } from '../../redux/actions/cardGroupActions';




const categories = [
    { group_path: 'engagement', group_label: 'Engagement Invitation Card' },
    { group_path: 'wedding', group_label: 'Wedding Invitation Card' },
    // {group_path: '1st-birthday' , group_label: '1st birthday invitation card'},
    // {group_path: 'anniversary' , group_label: 'Anniversary Cards'},
    // {group_path: 'birthday' , group_label: 'Birthday Invitation Card'},
    // {group_path: 'engagement' , group_label: 'Engagement Invitation Card'},
    // {group_path: 'birthday_card_in_hindi' , group_label: 'Birthday invitation card in hindi'},
    { group_path: 'housewarming', group_label: 'Griha pravesh/House Warming' },
    // {group_path: 'haldi_kumkum' , group_label: 'Haaldi kumkum invitation'},
    // {group_path: 'kitty_party' , group_label: 'Kitty party invitation'},
    // {group_path: 'marathi_engagement' , group_label: 'Marathi engagement invitation card'},
    // {group_path: 'maata_ki_chowki' , group_label: 'Maata ki chowki'},
    // {group_path: 'muslim_wedding' , group_label: 'Muslim wedding cards'},
    // {group_path: 'naming_ceremony' , group_label: 'Naming Ceremony Invitation'},
    // {group_path: 'satyanarayan_pooja' , group_label: 'Satyanarayan pooja invitation in marathi'},
    // {group_path: 'shok_sandesh' , group_label: 'Shok sandesh shradhanjali card'},
    // {group_path: 'haldi_invitation' , group_label: 'Haldi invitation card'},
    // {group_path: 'sangeet_invitation' , group_label: 'Sangeet invitation card'},
    // {group_path: 'lagna_patrika' , group_label: 'Lagan patrika'},
    // {group_path: 'vastu_shanti' , group_label: 'Vastu shanti invitation card'},
    // {group_path: 'biodata_format_for_boy' , group_label: 'Biodata format for marriage for boy'},
    // {group_path: 'annaprashan_card' , group_label: 'Annaprashan Card'},
    // {group_path: 'biodata_in_hindi' , group_label: 'Biodata in Hindi'},
    // {group_path: 'biodata_format_for_girl' , group_label: 'Biodata format for marriage for girl'},

    // {group_path: 'buddha_marriage_biodata' , group_label: 'Buddha Marriage Biodata'},
    // {group_path: 'christian_marriage_biodata' , group_label: 'Christian Marriage Biodata'},
    // {group_path: 'christian_marriage_biodata_one_page' , group_label: 'Christian Marriage Biodata: 1 page'},
    // {group_path: 'christian_marriage_biodata' , group_label: 'Christian Marriage Biodata: 2 page'},

];




function CardSidebar() {
    const navigate = useNavigate();

    const [sidebarData, setSidebarData] = useState([]);

    useEffect(() => {
        const fetchSidebarData = async () => {
            const response = await getAllSidebarItems();
            if(response){
                console.log('response data', response);
                setSidebarData(response?.data?.data);
            }
            
        };
        fetchSidebarData();
    }, []);

    const handleNavigation = (group_path) => {
        navigate(`/cards/${group_path}`);

    }
    return (
        <>
            <Grid
                item
                xs={12}
                sm={3}
                md={2}
                sx={{
                    borderRadius: '10px',
                    overflow: 'hidden',
                    padding: '5px 0',
                    backgroundColor: '#e0e0e0',
                }}
            >
                <List>
                    {sidebarData?.map((category, index) => (
                        <React.Fragment key={index}>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handleNavigation(category.group_path)}>
                                    <ListItemText sx={{ color: '#616161' }} primary={category.group_name} />
                                </ListItemButton>
                            </ListItem>
                            <Divider sx={{ borderStyle: 'dotted', borderColor: '#aaa', borderWidth: '0 0 1px 0' }} />
                        </React.Fragment>
                    ))}
                </List>
            </Grid>

        </>
    )
}

export default CardSidebar