import React, { useEffect, useState } from "react";
import { Box, Typography, Button, TextField, Avatar, Paper, Divider } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import { createInteractions, openInteractions } from "../../redux/actions/reportActions";

const Interactions = () => {
    const navigate = useNavigate();
    const params = useParams();
    console.log("Id", params?.id);
    const [myInteractions, setMyInteractions] = useState([])

    useEffect(() => {
        const fetchInteractions = async () => {
            const response = await openInteractions(params?.id);
            console.log("interactions", response);
            if (response) {
                setMyInteractions(response?.data?.data);
            }

        };
        fetchInteractions();
    }, []);

    const [message, setMessage] = useState("");
    const getPlainText = (html) => {
        const doc = new DOMParser().parseFromString(html, "text/html");
        return doc.body.textContent || "";
    };

    const sendInteractionMessage = async () => {
        const plainTextMessage = getPlainText(message);
        console.log("Sending:", plainTextMessage);
        const formData = {id: params?.id, notes: plainTextMessage}

        const response = await createInteractions(formData);
    }
    return (
        <Box sx={{ maxWidth: "800px", margin: "auto", padding: "20px" }}>
            {/* Header */}
            <Box display="flex" alignItems="center" mb={2}>
                <ArrowBack
                    sx={{ cursor: "pointer", mr: 1 }}
                    onClick={() => navigate(-1)}
                />
                <Typography variant="body1" color="primary">
                    My Tickets
                </Typography>
            </Box>

            {/* Ticket Title and Status */}
            <Typography variant="h6" fontWeight="bold">
                #[Ticket {myInteractions?.ticket?.id}]
            </Typography>
            <Typography variant="body2" color="gray" mt={1}>
                Status: <strong>{myInteractions?.ticket?.status}</strong>
            </Typography>

            {/* Interaction Box */}
            <Paper
                elevation={3}
                sx={{
                    padding: "15px",
                    mt: 3,
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "250px"
                }}
            >
                <Box sx={{ flexGrow: 1, overflow: "hidden", minHeight: "150px" }}>
                    <ReactQuill
                        theme="snow"
                        placeholder="Type your response..."
                        style={{ height: "150px" }}
                        value={message}
                        onChange={setMessage}
                    />
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    sx={{ width: "auto", alignSelf: "flex-start", mt: 2 }}
                    onClick={sendInteractionMessage}
                >
                    Submit
                </Button>
            </Paper>




            {/* Support Response */}
            {myInteractions?.interactions?.map((response, index) => (


                <Paper key={index} elevation={2} sx={{ padding: "15px", mt: 3, backgroundColor: '#eeeeee' }}>
                    <Box display="flex" alignItems="center">
                        <Avatar sx={{ mr: 2 }} />
                        <Box>
                            <Typography fontWeight="bold">
                                {response?.associated_support_rep || "Customer"}
                            </Typography>
                            <Typography variant="body2" color="gray">
                                {new Date(response?.updatedAt).toLocaleString("en-US", {
                                    month: "long",
                                    day: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                })}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body1">
                        {response?.notes || "Hello, Thank you for contacting support."}
                    </Typography>
                </Paper>
            ))}

        </Box>
    );
}

export default Interactions