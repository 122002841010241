import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";


export const createDigitalCardV2 = async (card_id, cardData) => {
    const { data } = await api.attemptDigitalCardEntry(card_id, cardData);
    return data;
};

export const createDigitalCardForCustomerData = async (card_id, cardData) => {
    const { data } = await api.markDigitalCard(card_id, cardData);
    return data;
};

export const getAllDigitalCardRequest = () => ({
    type: actionTypes.GET_ALL_DIGITAL_CARDS_REQUEST,
});

export const getAllDigitalCardSuccess = (data) => ({
    type: actionTypes.GET_ALL_DIGITAL_CARDS_SUCCESS,
    payload: data,
});
export const getAllDigitalCardFailure = (error) => ({
    type: actionTypes.GET_ALL_DIGITAL_CARDS_FAILURE,
    payload: error,
});

export const getAllDigitalCardData = () => {
    return async (dispatch) => {
        dispatch(getAllDigitalCardRequest());
        try{
            const {data} = await api.getAllDigitalCard();
            dispatch(getAllDigitalCardSuccess(data));
            return data;
        }catch (error){
            dispatch(getAllDigitalCardFailure(error));
        }
    };
};

export const sendDigitalInvitationCardData = async (formData) => {
    console.log("step 1", formData);
    const {data} = await api.sendDigitalInvitation(formData.id, formData);
    console.log("response in digital action", data);
    return data;
};

export const updateDigitalInvitationCardData = async (formData) => {
    const response = await api.updateDigitalInvitationCard(formData.id, formData);
    return response.data;
};

export const viewDigitalInvitationCardData = async (formData) => {
    const response = await api.viewDigitalInvitationCard(formData);
    return response;
}

export const deleteDigitalCardData = async (formData) => {
    const response = await api.deleteDigitalCard(formData.id);
    return response;
}
 export const getSingleDigitalCardRequest = () => ({
    type: actionTypes.GET_SINGLE_DIGITAL_CARD_REQUEST,
 });

 export const getSingleDigitalCardSuccess = (data) => ({
    type: actionTypes.GET_SINGLE_DIGITAL_CARD_SUCCESS,
    payload: data,
 });

 export const getSingleDigitalCardFailure = (error) => ({
    type: actionTypes.GET_SINGLE_DIGITAL_CARD_FAILURE,
    paylaod: error,
 });

 export const getSingleDigitalCardData = (id) => {
    return async (dispatch) => {
        dispatch(getSingleDigitalCardRequest());
        try{
            const response = await api.getSingleDigitalCard(id);
            dispatch(getSingleDigitalCardSuccess(response));
            return response;
        } catch (error) {
            dispatch(getSingleDigitalCardFailure(error));
        }
    };
 };

