import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    Card, 
    CardContent,
    Typography, 
    Button, 
    Paper, 
    Box, 
    IconButton,
    Container,
    Alert,
    Fade,
    useTheme,
    useMediaQuery,
    Chip,
    Grid,
    Divider,
    CircularProgress
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { bundlePaymentSuccess, getBundlesData } from '../../redux/actions/bundleActions';
import LinearProgress from '@mui/material/LinearProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TimerIcon from '@mui/icons-material/Timer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import lock_1a from '../../assets/icons/lock_1a.png';
import lock_1b from '../../assets/icons/lock_1b.png';
import ProcessingDialog from './ProgressingDialog';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const MediaViewer = ({ open, onClose, item, payment_data }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = React.useRef(null);
    const [imageLoaded, setImageLoaded] = useState(false);

    // Reset state when dialog closes
    useEffect(() => {
        if (!open) {
            setIsPlaying(false);
            setImageLoaded(false);
        }
    }, [open]);

    const handlePlayPause = (e) => {
        e?.stopPropagation();
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleView = (e) => {
        e?.stopPropagation();
        window.open(item.final_url, '_blank');
    };

    const handleDownload = async (e) => {
        e?.stopPropagation();
        if (!item?.final_url) return;
        try {
            const response = await fetch(item.final_url);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const urlParts = item.final_url.split('.');
            const extension = urlParts[urlParts.length - 1];
            const filename = (item.title).split("::")[1] + `_final.${extension}`;
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download failed:", error);
        }
    };

    const handleDialogClick = (e) => {
        e.stopPropagation();
    };

    const handleClose = (e) => {
        e?.stopPropagation();
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
            TransitionComponent={Fade}
            onClick={handleDialogClick}
            sx={{
                '& .MuiDialog-paper': {
                    m: { xs: 0, sm: 2 },
                    bgcolor: 'background.default',
                    backgroundImage: 'none'
                },
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)'
                }
            }}
        >
            <DialogContent 
                sx={{ p: 0, position: 'relative' }}
                onClick={handleDialogClick}
            >
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        '&:hover': {
                            bgcolor: 'rgba(0, 0, 0, 0.7)'
                        },
                        zIndex: 1
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Box sx={{ 
                    width: '100%', 
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    bgcolor: 'background.default'
                }}>
                    {item?.final_mime?.includes('video') ? (
                        <Box sx={{ width: '100%', position: 'relative' }}>
                            <video
                                ref={videoRef}
                                src={item.final_url}
                                style={{ 
                                    width: '100%', 
                                    maxHeight: '80vh', 
                                    objectFit: 'contain',
                                    backgroundColor: 'black'
                                }}
                                controls={!item.isLocked}
                                onClick={(e) => e.stopPropagation()}
                                playsInline
                                preload="metadata"
                            />
                            {item.isLocked && (
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handlePlayPause(e);
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                                        color: 'white',
                                        '&:hover': {
                                            bgcolor: 'rgba(0, 0, 0, 0.7)'
                                        }
                                    }}
                                >
                                    {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                                </IconButton>
                            )}
                        </Box>
                    ) : (
                        <Box sx={{ 
                            width: '100%', 
                            position: 'relative',
                            bgcolor: 'black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '50vh'
                        }}>
                            {!imageLoaded && (
                                <Box sx={{ 
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    height: '100%'
                                }}>
                                    <CircularProgress />
                                </Box>
                            )}
                            <img
                                src={item.final_url || item.thumbnail}
                                alt={item.title}
                                style={{ 
                                    maxWidth: '100%',
                                    maxHeight: '80vh',
                                    objectFit: 'contain',
                                    display: imageLoaded ? 'block' : 'none'
                                }}
                                onClick={(e) => e.stopPropagation()}
                                onLoad={() => setImageLoaded(true)}
                                loading="lazy"
                                crossOrigin="anonymous"
                            />
                        </Box>
                    )}

                    <Box sx={{ 
                        mt: 2,
                        px: 2,
                        pb: 2,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2
                    }}>
                        <Button
                            variant="outlined"
                            startIcon={<VisibilityIcon />}
                            disabled={item.isLocked}
                            onClick={handleView}
                            size="large"
                        >
                            {payment_data?.payment_status === 'not_needed' || payment_data?.payment_status === 'captured' ? 
                                "View Original" : "View Sample"}
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<CloudDownloadIcon />}
                            disabled={item.isLocked}
                            onClick={handleDownload}
                            size="large"
                        >
                            {payment_data?.payment_status === 'not_needed' || payment_data?.payment_status === 'captured' ? 
                                "Download Original" : "Download Sample"}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

const MediaCard = ({ item, type, payment_data, handleDownload }) => {
    const [viewerOpen, setViewerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleCardView = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(item.final_url);
    };

    const handleCardDownload = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleDownload(item);
    };

    const getTitle = (title) => {
        if (!title) return '';
        return title.split("::")[1];
    };

    return (
        <>
            <Paper 
                elevation={0}
                sx={{ 
                    p: { xs: 1.5, sm: 2 },
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 2,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    position: 'relative',
                    zIndex: 1,
                    '&:hover': {
                        boxShadow: theme.shadows[4],
                        transform: 'translateY(-2px)',
                        zIndex: 2
                    }
                }}
            >
                <Box 
                    sx={{ 
                        position: 'relative', 
                        mb: 1.5,
                        cursor: 'pointer'
                    }}
                    onClick={() => setViewerOpen(true)}
                >
                    {item.thumbnail ? (
                        <Box sx={{ position: 'relative' }}>
                            <img
                                src={item.thumbnail}
                                alt={item.title}
                                style={{ 
                                    width: '100%',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    display: 'block'
                                }}
                            />
                            {item.isLocked && (
                                <Box sx={{ 
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: 'rgba(0, 0, 0, 0.6)',
                                    borderRadius: '8px',
                                    backdropFilter: 'blur(2px)'
                                }}>
                                    <img
                                        src={lock_1a}
                                        style={{ width: '45px', height: '45px' }}
                                        alt='locked'
                                    />
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box sx={{ 
                            width: '100%',
                            aspectRatio: '16/9',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'rgba(0, 0, 0, 0.04)',
                            borderRadius: 2
                        }}>
                            {type === 'pdf' ? (
                                <PictureAsPdfIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
                            ) : (
                                <VideoFileIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
                            )}
                        </Box>
                    )}
                </Box>

                <Box sx={{ flexGrow: 1, mb: 1 }}>
                    <Typography 
                        variant="subtitle1" 
                        sx={{ 
                            fontWeight: 600,
                            mb: 0.5,
                            fontSize: { xs: '1rem', sm: '1.125rem' },
                            lineHeight: 1.3
                        }}
                    >
                        {getTitle(item?.title)}
                    </Typography>
                    
                    {item.isLocked && (
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 1,
                            mt: 0.5
                        }}>
                            <img
                                src={lock_1b}
                                style={{ width: '18px', height: '18px' }}
                                alt='lock'
                            />
                            <Typography 
                                variant='body2'
                                sx={{
                                    color: '#DC7900',
                                    fontWeight: 600,
                                    fontSize: '0.875rem'
                                }}
                            >
                                Pay to Unlock Original
                            </Typography>
                        </Box>
                    )}
                </Box>

                <Box sx={{ 
                    display: 'flex',
                    gap: 1,
                    flexDirection: isMobile ? 'column' : 'row'
                }}>
                    {item?.final_url && (
                        <>
                            <Button
                                variant="outlined"
                                startIcon={<VisibilityIcon />}
                                disabled={item.isLocked}
                                onClick={handleCardView}
                                fullWidth={isMobile}
                                size="medium"
                                sx={{ 
                                    py: { xs: 1, sm: 0.75 },
                                    fontSize: '0.875rem'
                                }}
                            >
                                {payment_data?.payment_status === 'not_needed' || payment_data?.payment_status === 'captured' ? 
                                    "View" : "View Sample"}
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<CloudDownloadIcon />}
                                disabled={item.isLocked}
                                onClick={handleCardDownload}
                                fullWidth={isMobile}
                                size="medium"
                                sx={{ 
                                    py: { xs: 1, sm: 0.75 },
                                    fontSize: '0.875rem'
                                }}
                            >
                                {payment_data?.payment_status === 'not_needed' || payment_data?.payment_status === 'captured' ? 
                                    "Download" : "Download Sample"}
                            </Button>
                        </>
                    )}
                </Box>
            </Paper>
            <MediaViewer 
                open={viewerOpen}
                onClose={() => setViewerOpen(false)}
                item={item}
                payment_data={payment_data}
            />
        </>
    );
};

const MediaSection = ({ title, items, type, payment_data, handleDownload }) => {
    if (!items.length) return null;
    
    return (
        <Box sx={{ mb: 3 }}>
            <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: 1.5,
                px: { xs: 2, sm: 0 }
            }}>
                {type === 'pdf' ? (
                    <PictureAsPdfIcon sx={{ fontSize: 24, color: 'primary.main' }} />
                ) : (
                    <VideoFileIcon sx={{ fontSize: 24, color: 'primary.main' }} />
                )}
                <Typography 
                    variant="h6" 
                    sx={{ 
                        fontWeight: 600,
                        fontSize: { xs: '1.125rem', sm: '1.25rem' }
                    }}
                >
                    {title}
                </Typography>
            </Box>
            <Grid 
                container 
                spacing={{ xs: 1.5, sm: 2 }}
                sx={{
                    '& .MuiGrid-item': {
                        position: 'relative',
                        zIndex: 1
                    }
                }}
            >
                {items.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <MediaCard 
                            item={item} 
                            type={type} 
                            payment_data={payment_data}
                            handleDownload={handleDownload}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

const PublicPageForOrders = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { order_id } = useParams();
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [timer, setTimer] = useState(600);

    const bundle = useSelector((state) => state?.bundleData?.bundlesData?.data?.rows);
    const payment_data = useSelector((state) => state?.bundleData?.bundlesData?.bundle_data);

    // Group items by type
    const groupedItems = React.useMemo(() => {
        if (!bundle) return { pdfs: [], videos: [] };
        return bundle.reduce((acc, item) => {
            if (item.final_mime?.includes('pdf')) {
                acc.pdfs.push(item);
            } else if (item.final_mime?.includes('video') || item.final_mime?.includes('mp4')) {
                acc.videos.push(item);
            }
            return acc;
        }, { pdfs: [], videos: [] });
    }, [bundle]);

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function initiateRazorPayPayment(amount, currency, order_name, order_description, rzpOrderId, rzpKey, customer_name, customer_email, customer_mobile, entry_id) {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }


        const options = {
            key: rzpKey, // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: order_name,
            description: order_description,
            // image: { logo },
            order_id: rzpOrderId,
            handler: async function (response) {
                console.log("Razorpay Response", response);
                const data = {
                    entry_id: entry_id,
                    orderCreationId: rzpOrderId,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    bundle_id: order_id
                };

                // let paymentReconcileData = {}
                let paymentReconcileData = await bundlePaymentSuccess(data);


                console.log("Payment Success", data);
                if (paymentReconcileData && paymentReconcileData.data) {
                    switch (paymentReconcileData.data.action) {
                        case 'payment_success_screen':
                            navigate(`/payment-successful/${order_id}`);
                            break;
                        case 'payment_waiting_screen':
                            /** Navigate to payment waiting screen*/
                            navigate(`/payment-waiting/${order_id}`);
                            break;
                        case 'payment_failed_screen':
                            /**navigate to payment failed screen */
                            navigate(`/payment-failed/${order_id}`);
                            break;
                        default:
                            navigate(`/payment-failed/${order_id}`);
                            break;
                    }
                }
            },
            prefill: {
                name: customer_name,
                email: customer_email,
                contact: customer_mobile,
            },
            // notes: {
            //   address: "Soumya Dey Corporate Office",
            // },
            theme: {
                color: "#61dafb",
            },
            "modal": {
                "ondismiss": function () {
                    navigate(`/payment-failed/${order_id}`);
                }
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    /**
     * 
    let payment_data = {
    "order_id": null,
    "amount": 11,
    "original_amount": 249,
    "customer_mobile": "9450162895",
    "customer_name": "Indrajeet Singh",
    "customer_email": "adeupm@gmail.com",
    "payment_req_object": {
        "status": "success",
        "message": "Order created successfully",
        "data": {
            "orderId": "order_PJb9bhTWdI1mO2",
            "amount": 1100,
            "currency": "INR",
            "receipt": "744d1f69-f9b1-4acd-b639-97a268016b92",
            "status": "created",
            "attempts": 0,
            "created_at": 1731240871,
            "offer_id": null
        },
        "key": "rzp_live_L3aKyZWl8SRyYR"
    }
}
     */

    const initiatePaymentSteps = (payment_data) => {
        // payment_data = JSON.parse(payment_data);



        initiateRazorPayPayment(
            payment_data.payment_req_object.data.amount,
            payment_data.payment_req_object.data.currency,
            "E-invite",
            "Unlock E-invite",
            payment_data.payment_req_object.data.orderId,
            payment_data.payment_req_object.data.key,
            payment_data.customer_name,
            payment_data.customer_email,
            payment_data.customer_mobile,
            order_id
        )
    };

    useEffect(() => {
        const fetchBundleData = async () => {
            const id = { bundle_id: order_id };
            setLoading(true);
            await dispatch(getBundlesData(id));
            setLoading(false)

        };
        fetchBundleData();
    }, [order_id, dispatch]);


    useEffect(() => {

        if (timer === 0) {
            window.location.reload();
        }
        const interval = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000 * 1);
        return () => clearInterval(interval);
    }, [timer]);

    const handleDownloadAll = async () => {
        await setOpenDialog(true);
        await bundle.forEach(product => {
            if (product.final_url) {
                handleDownload(product);
            }
        });
        await setOpenDialog(false);
    };

    const handleDownload = async (card) => {
        if (!card?.final_url) return;

        try {
            const response = await fetch(card.final_url);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const urlParts = card.final_url.split('.');
            const extension = urlParts[urlParts.length - 1];

            const filename = (card.title).split("::")[1] + `_final.${extension}`;

            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download failed:", error);
        }
    };

    return (
        <Container 
            maxWidth="lg" 
            sx={{ 
                py: { xs: 2, sm: 4 },
                px: { xs: 0, sm: 3 }
            }}
        >
            <Fade in={true}>
                <Card 
                    elevation={2}
                    sx={{ 
                        borderRadius: { xs: 0, sm: 3 },
                        background: theme.palette.mode === 'dark'
                            ? 'linear-gradient(135deg, rgba(40, 40, 80, 0.95), rgba(30, 30, 60, 0.85))'
                            : 'linear-gradient(135deg, rgba(248, 250, 255, 0.95), rgba(240, 245, 255, 0.85))',
                    }}
                >
                    <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: { xs: 'column', sm: 'row' }, 
                            justifyContent: 'space-between',
                            alignItems: { xs: 'flex-start', sm: 'center' },
                            mb: 3,
                            gap: 2
                        }}>
                            <Box>
                                <Typography 
                                    variant="h5" 
                                    sx={{ 
                                        mb: 1, 
                                        fontWeight: 600,
                                        fontSize: { xs: '1.375rem', sm: '1.5rem' },
                                        lineHeight: 1.3
                                    }}
                                >
                                    Welcome, {' '}
                                    <Box component="span" sx={{ 
                                        color: theme.palette.primary.main,
                                        fontWeight: 700 
                                    }}>
                                        {payment_data?.customer_name}
                                    </Box>
                    </Typography>
                </Box>
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                bgcolor: 'rgba(0, 0, 0, 0.04)',
                                px: 2,
                                py: 1,
                                borderRadius: 2,
                                width: { xs: '100%', sm: 'auto' }
                            }}>
                                <TimerIcon sx={{ 
                                    mr: 1, 
                                    color: theme.palette.text.secondary,
                                    fontSize: 20
                                }} />
                                <Typography 
                                    sx={{ 
                                        color: theme.palette.text.secondary,
                                        fontSize: '0.9375rem',
                                        fontWeight: 500
                                    }}
                                >
                                    Refreshing in {timer}s
                                </Typography>
                        </Box>
                        </Box>

                        {payment_data?.payment_status === 'not_needed' || payment_data?.payment_status === 'captured' ? (
                            <Alert 
                                severity="success" 
                                sx={{ 
                                    mb: 3, 
                                    borderRadius: 2,
                                    '& .MuiAlert-message': {
                                        fontSize: '1rem'
                                    }
                                }}
                            >
                                Your e-invites have been successfully generated and are ready for download!
                            </Alert>
                        ) : (
                            <Alert 
                                severity="info"
                                sx={{ 
                                    mb: 3, 
                                    borderRadius: 2,
                                    '& .MuiAlert-message': {
                                        fontSize: '1rem'
                                    }
                                }}
                            >
                                Your e-invites have been generated. Please complete the payment to unlock the full versions.
                            </Alert>
                        )}

                        {loading ? (
                            <Box sx={{ p: 4, textAlign: 'center' }}>
                                <LinearProgress sx={{ mb: 3 }} />
                                <Typography 
                                    sx={{ 
                                        fontSize: '1rem',
                                        color: 'text.secondary'
                                    }}
                                >
                                    Please wait, fetching your e-invites...
                                </Typography>
                                                    </Box>
                                            ) : (
                                                <>
                                <MediaSection 
                                    title="PDF Invitations" 
                                    items={groupedItems.pdfs}
                                    type="pdf"
                                    payment_data={payment_data}
                                    handleDownload={handleDownload}
                                />
                                <MediaSection 
                                    title="Video Invitations" 
                                    items={groupedItems.videos}
                                    type="video"
                                    payment_data={payment_data}
                                    handleDownload={handleDownload}
                                />

                                {(payment_data?.payment_status === 'not_needed' || payment_data?.payment_status === 'captured') ? (
                                    <>
                                        <Box sx={{ px: { xs: 2, sm: 0 } }}>
                                                        <Button
                                                variant="contained"
                                                startIcon={<CloudDownloadIcon />}
                                                onClick={handleDownloadAll}
                                                fullWidth
                                                size="large"
                                                sx={{ 
                                                    py: 1.5,
                                                    background: 'linear-gradient(45deg, #2196f3 30%, #1976d2 90%)',
                                                    boxShadow: '0 3px 10px rgba(33, 150, 243, 0.2)',
                                                    '&:hover': {
                                                        background: 'linear-gradient(45deg, #1e88e5 30%, #1565c0 90%)',
                                                    },
                                                    fontSize: '1rem',
                                                    fontWeight: 600
                                                }}
                                            >
                                                Download All E-invites
                                                        </Button>

                                            <Box sx={{ mt: 4, textAlign: 'center' }}>
                                                <Typography 
                                                    variant="body1" 
                                                    sx={{ 
                                                        color: 'text.secondary',
                                                        fontStyle: 'italic',
                                                        maxWidth: '600px',
                                                        mx: 'auto',
                                                        lineHeight: 1.8,
                                                        fontSize: '1rem',
                                                        px: { xs: 2, sm: 0 }
                                                    }}
                                                >
                                                    May your wedding day be filled with love, laughter, and unforgettable memories. 
                                                    As you and your partner begin this new chapter together, we wish you both a lifetime 
                                                    of happiness, understanding, and adventure.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Box sx={{ 
                                            bgcolor: 'rgba(0, 0, 0, 0.02)',
                                            p: { xs: 2.5, sm: 3 },
                                            borderRadius: 3,
                                            border: `1px solid ${theme.palette.divider}`,
                                            mb: 3,
                                            mx: { xs: 2, sm: 0 }
                                        }}>
                                            <Box sx={{ 
                                                display: 'flex', 
                                                flexDirection: { xs: 'column', sm: 'row' },
                                                alignItems: { xs: 'flex-start', sm: 'center' },
                                                gap: 1.5,
                                                mb: 2.5
                                            }}>
                                                <Chip 
                                                    label={`${Math.round(((payment_data?.original_amount - payment_data?.amount) / payment_data?.original_amount) * 100)}% OFF`}
                                                    color="success"
                                                    sx={{ 
                                                        borderRadius: 1.5,
                                                        fontWeight: 600,
                                                        fontSize: '1rem',
                                                        height: 32,
                                                        px: 1
                                                    }}
                                                />
                                                <Typography 
                                                    sx={{ 
                                                        color: 'success.main',
                                                        fontWeight: 600,
                                                        fontSize: '0.9375rem'
                                                    }}
                                                >
                                                    Special Offer
                            </Typography>
                        </Box>

                                            <Box sx={{ 
                                                display: 'flex',
                                                alignItems: 'baseline',
                                                gap: 2,
                                                mb: 3,
                                                flexDirection: { xs: 'column', sm: 'row' }
                                            }}>
                                                <Typography 
                                                    variant="h4" 
                                                    sx={{ 
                                                        fontWeight: 700,
                                                        color: 'primary.main',
                                                        fontSize: { xs: '2rem', sm: '2.25rem' }
                                                    }}
                                                >
                                                    ₹{Math.floor(payment_data?.amount)}
                            </Typography>
                                                <Typography 
                                                    variant="body1" 
                                                    sx={{ 
                                                        textDecoration: 'line-through',
                                                        color: 'text.secondary',
                                                        fontSize: '1rem'
                                                    }}
                                                >
                                M.R.P: ₹{payment_data?.original_amount?.toLocaleString()}
                            </Typography>
                        </Box>

                                            <Button
                                                variant="contained"
                                                fullWidth
                                                size="large"
                                                onClick={() => initiatePaymentSteps(payment_data)}
                                                sx={{ 
                                                    py: 1.75,
                                                    background: 'linear-gradient(45deg, #d32f2f 30%, #c62828 90%)',
                                                    boxShadow: '0 3px 10px rgba(211, 47, 47, 0.2)',
                                                    '&:hover': {
                                                        background: 'linear-gradient(45deg, #c62828 30%, #b71c1c 90%)',
                                                    },
                                                    fontSize: '1.125rem',
                                                    fontWeight: 600
                                                }}
                                            >
                                                Pay Now & Unlock All E-invites
                        </Button>
                                        </Box>

                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 1,
                                            color: 'text.secondary',
                                            px: { xs: 2, sm: 0 }
                                        }}>
                                            <WhatsAppIcon sx={{ fontSize: 20 }} />
                                            <Typography 
                                                variant="body1"
                                                sx={{ fontSize: '0.9375rem' }}
                                            >
                                                Need help? WhatsApp us at +91 87651 40002
                                            </Typography>
                        </Box>
                    </>
                )}
                            </>
                        )}
                    </CardContent>
        </Card>
            </Fade>
            <ProcessingDialog open={openDialog} />
        </Container>
    );
};

export default PublicPageForOrders;