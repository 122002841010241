import React, { useState } from "react";
import {Container,Card,CardContent,Typography,TextField,Select,MenuItem,FormControl,InputLabel,Button,RadioGroup,FormControlLabel,Radio,IconButton,Box,} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { createSupportTicketData } from "../../redux/actions/reportActions";

const SupportTicket = () => {

    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {subject,description};
        console.log("Submitted Data:", formData);
        const response = await createSupportTicketData(formData);
        console.log("response", response);
    };

    return (
        <Container maxWidth="sm">
            <Card sx={{ mt: 4, p: 2 }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Submit a Support Ticket
                    </Typography>
                    <form onSubmit={handleSubmit}>
                       
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Issue subject</InputLabel>
                            <Select
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                required
                            >
                                <MenuItem value="Profile-setup">Profile Setup</MenuItem>
                                <MenuItem value="Callback-setup">Callback Request</MenuItem>
                                <MenuItem value="Profile-update">Profile Update</MenuItem>
                                <MenuItem value="Digital-invitation">Digital Invitation</MenuItem>
                                <MenuItem value="Technical-issue">Technical Issue</MenuItem>
                                <MenuItem value="Billing-inquery">Billing Inquery</MenuItem>
                                <MenuItem value="Feature-request">Feature Request</MenuItem>
                                <MenuItem value="Account-support">Account Support</MenuItem>
                                <MenuItem value="Payment-issue">Payment Issue</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>
                       
                        <TextField
                            fullWidth
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            margin="normal"
                            multiline
                            rows={4}
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 3 }}
                        >
                            Submit Ticket
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Container>
    );
};

export default SupportTicket;