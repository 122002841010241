import React, { useEffect, useState, useRef } from 'react';

import Typography from '@mui/material/Typography';
import { Box, Button, Card, CardContent, CardMedia, Dialog, Grid, Rating, Paper, IconButton } from '@mui/material';
import { Description, ThumbUp, HeadsetMic } from '@mui/icons-material';

import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllCardSuggestionData } from '../../redux/actions/getAllCardSuggestionActions'
import GooglePlay from '../../../src/assets/icons/GooglePlay.png';
import appleStore from '../../../src/assets/icons/appleStore.jpg';
import { LinearProgress } from '@mui/material';

import engagement from '../../assets/main-icon/engagement.png'
import wedding1 from '../../assets/main-icon/wedding1.png';
import biodata from '../../assets/main-icon/biodata.png';
import houseWarming from '../../assets/main-icon/houseWarming.png';
import haldi from '../../assets/main-icon/haldi.png';
import sangeet from '../../assets/main-icon/Sangeet.png';
import MuslimWedding from '../../assets/main-icon/MuslimWedding.png';
import Mundan from '../../assets/main-icon/Mundan.png';
import Birthday from '../../assets/main-icon/Birthday.png';
import Annaprashan from '../../assets/main-icon/Annaprashan.png'
import Anniversary from '../../assets/main-icon/Anniversary.png'
import Loading from '../Loading';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import config from '../../config/config';




const categories = [
  { label: 'Engagement Card', img: engagement, path: 'engagement' },
  { label: 'Marriage Invitation', img: wedding1, path: 'wedding' },
  { label: 'Marriage Biodata', img: biodata, path: 'biodata' },
  { label: 'House Warming Card', img: houseWarming, path: 'housewarming' },
  { label: 'Haldi Invitation', img: haldi, path: 'haldi' },
  { label: 'Sangeet Invitation', img: sangeet, path: 'sangeet' },
  { label: 'Muslim Wedding Cards', img: MuslimWedding, path: 'muslim-wedding' },
  { label: 'Mundan Card', img: Mundan, path: 'mundan' },
  { label: 'Birthday Invitation', img: Birthday, path: 'birthday-invitation-card' },
  { label: 'Annaprashan Invitation', img: Annaprashan, path: 'annaprashan-card' },
  { label: 'Anniversary Invitation', img: Anniversary, path: 'anniversary' },
];





const HomePage = () => {


  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  // fetch card suggestion data 
  const params = useParams();
  console.log("params", params);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const limit = 50;

  const cardSuggestion = useSelector((state) => state?.getAllCardSuggestions?.getAllCardSuggestion?.data);

  console.log("Card suggestion in home page", cardSuggestion);

  const engagementCardSuggestions = useSelector((state) => state?.getAllCardSuggestions?.engagement?.getAllCardSuggestion?.data);
  const housewarmingCardSuggestions = useSelector((state) => state?.getAllCardSuggestions?.housewarming?.getAllCardSuggestion?.data);
  // const birthdayCardSuggestions = useSelector((state) => state?.getAllCardSuggestions?.birthday?.getAllCardSuggestion?.data);
  const weddingCardSuggestions = useSelector((state) => state?.getAllCardSuggestions?.wedding?.getAllCardSuggestion?.data);
  const muslimWeddingCardSuggestions = useSelector((state) => state?.getAllCardSuggestions['muslim-wedding']?.getAllCardSuggestion?.data);
  const shopOpeningCardSuggestions = useSelector((state) => state?.getAllCardSuggestions['shop-opening']?.getAllCardSuggestion?.data);

  console.log("Data according group_path", muslimWeddingCardSuggestions);


  const [loading, setLoading] = useState(false);
  useEffect(() => {

    const fetchAllCardSuggestion = async () => {
      setLoading(true);
      await dispatch(getAllCardSuggestionData("engagement", "video", limit));
      await dispatch(getAllCardSuggestionData("wedding", "video", limit));
      await dispatch(getAllCardSuggestionData("housewarming", "pdf", limit));
      await dispatch(getAllCardSuggestionData("muslim-wedding", "pdf", limit));
      await dispatch(getAllCardSuggestionData("shop-opening", "pdf", limit));
      setLoading(false);
    };
    fetchAllCardSuggestion();

  }, [dispatch]);

  const [playingVideos, setPlayingVideos] = useState({});

  const handlePlayVideo = (index) => {
    setPlayingVideos((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };




  //display cards img

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedimage] = useState(null);



  const handleClose = () => {
    setOpen(false);
    setSelectedimage(null);
  }

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, []);

  const handleNavigation = (path_url) => {
    navigate(`/cards/${path_url}`);
  };

  // Add this heading style object
  const sectionHeadingStyle = {
    fontWeight: 700,
    color: isDarkMode ? 'rgba(255, 255, 255, 0.9)' : '#2d2d2d',
    mb: 4,
    position: 'relative',
    textAlign: 'center',
    '&::after': {
      content: '""',
      display: 'block',
      width: '60px',
      height: '4px',
      backgroundColor: isDarkMode ? '#BB86FC' : '#ef5350',
      margin: '16px auto 0',
      borderRadius: '2px'
    }
  };

  const TestimonialCarousel = ({ testimonials, isDarkMode }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [progress, setProgress] = useState(100);
    const sliderRef = useRef(null);

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: window.innerWidth > 600 ? 2 : 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      beforeChange: (oldIndex, newIndex) => {
        setCurrentSlide(newIndex);
        setProgress(100);
      },
      afterChange: (current) => setCurrentSlide(current),
      cssEase: 'ease-in-out',
      centerMode: true,
      centerPadding: '0',
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            centerMode: false
          }
        }
      ]
    };

    useEffect(() => {
      const interval = setInterval(() => {
        setProgress(prev => {
          if (prev <= 0) {
            sliderRef.current.slickNext();
            return 100;
          }
          return prev - 1;
        });
      }, 30);

      return () => clearInterval(interval);
    }, []);




    return (
      <Box sx={{
        position: 'relative',
        maxWidth: '1200px',
        margin: '0 auto',
        px: 2
      }}>
        <Slider {...settings} ref={sliderRef}>
          {testimonials.map((testimonial, index) => (
            <Box key={index} sx={{
              px: 1,
              outline: 'none',
              '&:focus': { outline: 'none' }
            }}>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  m: 1,
                  p: 2,
                  backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : '#ffffff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 6
                  }
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    mb: 2,
                    color: isDarkMode ? 'black' : 'black',
                    position: 'relative',
                    '&::before': {
                      content: '"\\201C"',
                      fontSize: '3rem',
                      position: 'absolute',
                      top: '-20px',
                      left: '-10px',
                      color: isDarkMode ? '#BB86FC' : '#ef5350',
                      opacity: 0.3
                    }
                  }}
                >
                  {testimonial.text}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 2,
                    color: isDarkMode ? 'black' : 'black',
                    fontStyle: 'italic'
                  }}
                >
                  {testimonial.msg}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 500,
                    color: isDarkMode ? 'black' : 'black',
                    mt: 'auto'
                  }}
                >
                  - {testimonial.author}
                </Typography>
              </Box>
            </Box>
          ))}
        </Slider>
        <Box sx={{
          width: `${progress}%`,
          height: '4px',
          backgroundColor: isDarkMode ? '#BB86FC' : '#ef5350',
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          transition: 'width 0.03s linear',
          maxWidth: '1200px'
        }} />
      </Box>
    );
  };

  return (
    <div>
      {loading ? (
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <Loading />

        </Box>
      ) : (
        <>
          <Box
            sx={{
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '15px',
            }}
          >
            <img
              src="https://innovators.blr1.cdn.digitaloceanspaces.com/static/republic_day_banner.jpg"
              alt="Wedding"
              style={{ width: '100%', maxWidth: '1000px' }}
              onClick={() => navigate('/special-offer')}
            />
          </Box>
          <Box sx={{ backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#f5f5f5', padding: '20px 0' }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">

              {/* Unique Biodata Design */}
              <Grid item xs={12} sm={4}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <Description sx={{ fontSize: 50, color: isDarkMode ? '#B3B3B3' : '#F44336', marginRight: '10px' }} />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography sx={{ fontWeight: 'bold', color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black', fontSize: { xs: '1rem', md: '1.2rem' } }}>
                      Unique Design at Best Price
                    </Typography>
                    <Typography variant="body2" sx={{ maxWidth: '250px', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : '#757575', }}>
                      We provide Best Digital Invitations with an impressive and beautiful design exclusively on RajOffset Platform.
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Lowest Price Guarantee */}
              <Grid item xs={12} sm={4}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <ThumbUp sx={{ fontSize: 50, color: isDarkMode ? '#B3B3B3' : '#F44336', marginRight: '10px' }} />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black', fontSize: { xs: '1rem', md: '1.2rem' } }}>
                      Lowest Price Guarantee
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ maxWidth: '250px', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : '#757575' }}>
                      We provides the best quality product at the cheapest price. Customer's satisfaction is our main priority.
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* 24/7 Support */}
              <Grid item xs={12} sm={4}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <HeadsetMic sx={{ fontSize: 50, color: isDarkMode ? '#B3B3B3' : '#F44336', marginRight: '10px' }} />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black', fontSize: { xs: '1rem', md: '1.2rem' } }}>
                     Quick WhatsApp Support
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ maxWidth: '250px', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : '#757575' }}>
                      Our Support team is available from 10 AM to 5 PM to support any issues or suggestions.
                    </Typography>
                  </Box>
                </Box>
              </Grid>

            </Grid>
          </Box>


          <Box sx={{ padding: '20px 0' }}>
            <Typography
              variant="h3"
              sx={{
                ...sectionHeadingStyle,
                fontSize: { xs: '1.8rem', md: '2rem' }
              }}
            >
              Top Categories
            </Typography>
            <Grid container spacing={2} justifyContent="center" sx={{ width: '90%', marginX: 'auto' }}>
              {categories.map((category, index) => (
                <Grid item xs={6} sm={4} md={3} lg={1.5} key={index} textAlign="center">
                  {/* <Card sx={{ maxWidth: 150, maxHeight: 'full', margin: 'auto' }}>
                <CardMedia
                  component="img"
                  height="100"
                  image={category.img}
                  alt={category.label}
                  sx={{ objectFit: 'cover', borderRadius: 1 }}
                  onClick={() => handleClickOpen(category.img)}
                />
              </Card> */}
                  <img
                    src={category.img}
                    alt={category.label}
                    style={{ maxWidth: 150, maxHeight: 'full', margin: 'auto' }}
                    onClick={() => handleNavigation(category.path)}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      marginTop: '10px',
                      color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black',
                    }}
                  >
                    {category.label}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box p={3}>
            <Typography
              variant="h4"
              sx={{
                ...sectionHeadingStyle,
                fontSize: { xs: '1.8rem', md: '2rem' }
              }}
            >
              Wedding Invitation Cards
            </Typography>
            <Grid container spacing={3}>
              {weddingCardSuggestions?.rows?.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                  <Card
                    onClick={() => navigate(`/cards/wedding/video/${card.id}`)}
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: 6,
                        cursor: 'pointer'
                      }
                    }}
                  >
                    {!playingVideos[index] ? (
                      <div style={{ position: 'relative', width: '100%', height: '85%' }}>
                        <CardMedia component="img" image={card?.card_samples[0]?.thumbnail} alt={card.card_name} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handlePlayVideo(index);
                          }}
                          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#616161', fontSize: 64 }}
                        >
                          <PlayCircleOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    ) : (
                      <CardMedia component="video" controls autoPlay src={card?.card_samples[0]?.url} alt={card?.card_samples[0]?.thumbnail} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                    )}
                    <CardContent>
                      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: 1, marginBottom: { md: 1 } }}>
                        <Box bgcolor="#d32f2f" color="white" px={0.5} py={0.5} borderRadius="4px" display="inline-block" sx={{ fontSize: '0.725rem' }}>
                          {Math.round(((card?.original_price - card?.sale_price) / card?.original_price) * 100)}% off
                        </Box>
                        <Typography sx={{ fontSize: '0.825rem', color: 'red', fontWeight: 'bold' }}>{config.CURRENT_SALE_NAME}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, flexDirection: { xs: 'column', md: 'row' } }}>
                        <Typography variant="h6" color="textPrimary" fontWeight="bold" sx={{ fontSize: '1.25rem', lineHeight: 1 }}>
                          {Math.floor(card.sale_price) === 0 ? 'Free' : `₹${Math.floor(card.sale_price)}`}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through', fontSize: '0.875rem' }}>
                          M.R.P: ₹{card?.original_price.toLocaleString()}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '1rem' }}>{card.card_name}</Typography>
                      <Rating value={card.card_rating} readOnly />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                onClick={() => navigate(`/cards/wedding`)}
                variant="contained"
                color="secondary"
                sx={{
                  textTransform: 'none',
                  backgroundColor: isDarkMode ? '#BB86FC' : '#ef5350',
                  borderRadius: '8px',
                  padding: '10px 20px',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: isDarkMode ? '#9965f4' : '#d32f2f'
                  }
                }}
              >
                Browse More Wedding Invites
              </Button>
            </Box>
          </Box>

          <Box p={3}>
            <Typography
              variant="h4"
              sx={{
                ...sectionHeadingStyle,
                fontSize: { xs: '1.8rem', md: '2rem' }
              }}
            >
              Engagement Invitation Cards
            </Typography>
            <Grid container spacing={3}>
              {engagementCardSuggestions?.rows?.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                  <Card
                    onClick={() => navigate(`/cards/engagement/video/${card.id}`)}
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: 6,
                        cursor: 'pointer'
                      }
                    }}
                  >
                    {!playingVideos[index] ? (
                      <div style={{ position: 'relative', width: '100%', height: '85%' }}>
                        <CardMedia component="img" image={card?.card_samples[0]?.thumbnail} alt={card.card_name} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handlePlayVideo(index);
                          }}
                          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#616161', fontSize: 64 }}
                        >
                          <PlayCircleOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    ) : (
                      <CardMedia component="video" controls autoPlay src={card?.card_samples[0]?.url} alt={card?.card_samples[0]?.thumbnail} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                    )}
                    <CardContent>
                      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: 1, marginBottom: { md: 1 } }}>
                        <Box bgcolor="#d32f2f" color="white" px={0.5} py={0.5} borderRadius="4px" display="inline-block" sx={{ fontSize: '0.725rem' }}>
                          {Math.round(((card?.original_price - card?.sale_price) / card?.original_price) * 100)}% off
                        </Box>
                        <Typography sx={{ fontSize: '0.825rem', color: 'red', fontWeight: 'bold' }}>{config.CURRENT_SALE_NAME}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, flexDirection: { xs: 'column', md: 'row' } }}>
                        <Typography variant="h6" color="textPrimary" fontWeight="bold" sx={{ fontSize: '1.25rem', lineHeight: 1 }}>
                          {Math.floor(card.sale_price) === 0 ? 'Free' : `₹${Math.floor(card.sale_price)}`}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through', fontSize: '0.875rem' }}>
                          M.R.P: ₹{card?.original_price.toLocaleString()}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '1rem' }}>{card.card_name}</Typography>
                      <Rating value={card.card_rating} readOnly />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                onClick={() => navigate(`/cards/engagement`)}
                variant="contained"
                color="secondary"
                sx={{
                  textTransform: 'none',
                  backgroundColor: isDarkMode ? '#BB86FC' : '#ef5350',
                  borderRadius: '8px',
                  padding: '10px 20px',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: isDarkMode ? '#9965f4' : '#d32f2f'
                  }
                }}
              >
                Browse More Engagement Invites
              </Button>
            </Box>
          </Box>

          <Box p={3}>
            <Typography
              variant="h4"
              sx={{
                ...sectionHeadingStyle,
                fontSize: { xs: '1.8rem', md: '2rem' }
              }}
            >
              Muslim Wedding Invitation Cards
            </Typography>
            <Grid container spacing={3}>
              {muslimWeddingCardSuggestions?.rows?.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                  {card?.card_samples?.length > 0 && (
                    <Card
                      onClick={() => navigate(`/cards/muslim-wedding/pdf/${card.id}`)}
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: 6,
                          cursor: 'pointer'
                        }
                      }}
                    >
                      <CardMedia
                        component="img"
                        alt={card.card_samples[0].thumbnail}
                        sx={{
                          height: 'auto',
                          objectFit: 'contain',
                          aspectRatio: '3/4'
                        }}
                        image={card.card_samples[0].url}
                      />
                      <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: 1, marginBottom: { md: 1 } }}>
                          <Box bgcolor="#d32f2f" color="white" px={0.5} py={0.5} borderRadius="4px" display="inline-block" sx={{ fontSize: '0.725rem' }}>
                            {Math.round(((card?.original_price - card?.sale_price) / card?.original_price) * 100)}% off
                          </Box>
                          <Typography sx={{ fontSize: '0.825rem', color: 'red', fontWeight: 'bold' }}>{config.CURRENT_SALE_NAME}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, flexDirection: { xs: 'column', md: 'row' } }}>
                          <Typography variant="h6" color="textPrimary" fontWeight="bold" sx={{ fontSize: '1.25rem', lineHeight: 1 }}>
                            {Math.floor(card.sale_price) === 0 ? 'Free' : `₹${Math.floor(card.sale_price)}`}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through', fontSize: '0.875rem' }}>
                            M.R.P: ₹{card?.original_price.toLocaleString()}
                          </Typography>
                        </Box>
                        <Typography sx={{ fontSize: '1rem' }}>{card.card_name}</Typography>
                        <Rating value={card.card_rating} readOnly />
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                onClick={() => navigate(`/cards/muslim-wedding`)}
                variant="contained"
                color="secondary"
                sx={{
                  textTransform: 'none',
                  backgroundColor: isDarkMode ? '#BB86FC' : '#ef5350',
                  borderRadius: '8px',
                  padding: '10px 20px',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: isDarkMode ? '#9965f4' : '#d32f2f'
                  }
                }}
              >
                Browse More Muslim Wedding Invites
              </Button>
            </Box>
          </Box>

          <Box p={3}>
            <Typography
              variant="h4"
              sx={{
                ...sectionHeadingStyle,
                fontSize: { xs: '1.8rem', md: '2rem' }
              }}
            >
              Housewarming Invitation Cards
            </Typography>
            <Grid container spacing={3}>
              {housewarmingCardSuggestions?.rows?.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                  {card?.card_samples?.length > 0 && (
                    <Card
                      onClick={() => navigate(`/cards/housewarming/pdf/${card.id}`)}
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: 6,
                          cursor: 'pointer'
                        }
                      }}
                    >
                      <CardMedia
                        component="img"
                        alt={card.card_samples[0].thumbnail}
                        sx={{
                          height: 'auto',
                          objectFit: 'contain',
                          aspectRatio: '3/4'
                        }}
                        image={card.card_samples[0].url}
                      />
                      <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: 1, marginBottom: { md: 1 } }}>
                          <Box bgcolor="#d32f2f" color="white" px={0.5} py={0.5} borderRadius="4px" display="inline-block" sx={{ fontSize: '0.725rem' }}>
                            {Math.round(((card?.original_price - card?.sale_price) / card?.original_price) * 100)}% off
                          </Box>
                          <Typography sx={{ fontSize: '0.825rem', color: 'red', fontWeight: 'bold' }}>{config.CURRENT_SALE_NAME}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, flexDirection: { xs: 'column', md: 'row' } }}>
                          <Typography variant="h6" color="textPrimary" fontWeight="bold" sx={{ fontSize: '1.25rem', lineHeight: 1 }}>
                            {Math.floor(card.sale_price) === 0 ? 'Free' : `₹${Math.floor(card.sale_price)}`}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through', fontSize: '0.875rem' }}>
                            M.R.P: ₹{card?.original_price.toLocaleString()}
                          </Typography>
                        </Box>
                        <Typography sx={{ fontSize: '1rem' }}>{card.card_name}</Typography>
                        <Rating value={card.card_rating} readOnly />
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                onClick={() => navigate(`/cards/housewarming`)}
                variant="contained"
                color="secondary"
                sx={{
                  textTransform: 'none',
                  backgroundColor: isDarkMode ? '#BB86FC' : '#ef5350',
                  borderRadius: '8px',
                  padding: '10px 20px',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: isDarkMode ? '#9965f4' : '#d32f2f'
                  }
                }}
              >
                Browse More Housewarming Invites
              </Button>
            </Box>
          </Box>

          <Box p={3}>
            <Typography
              variant="h4"
              sx={{
                ...sectionHeadingStyle,
                fontSize: { xs: '1.8rem', md: '2rem' }
              }}
            >
              Shop Opening Invitation Cards
            </Typography>
            <Grid container spacing={3}>
              {shopOpeningCardSuggestions?.rows?.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                  {card?.card_samples?.length > 0 && (
                    <Card
                      onClick={() => navigate(`/cards/shop-opening/pdf/${card.id}`)}
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: 6,
                          cursor: 'pointer'
                        }
                      }}
                    >
                      <CardMedia
                        component="img"
                        alt={card.card_samples[0].thumbnail}
                        sx={{
                          height: 'auto',
                          objectFit: 'contain',
                          aspectRatio: '3/4'
                        }}
                        image={card.card_samples[0].url}
                      />
                      <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: 1, marginBottom: { md: 1 } }}>
                          <Box bgcolor="#d32f2f" color="white" px={0.5} py={0.5} borderRadius="4px" display="inline-block" sx={{ fontSize: '0.725rem' }}>
                            {Math.round(((card?.original_price - card?.sale_price) / card?.original_price) * 100)}% off
                          </Box>
                          <Typography sx={{ fontSize: '0.825rem', color: 'red', fontWeight: 'bold' }}>{config.CURRENT_SALE_NAME}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, flexDirection: { xs: 'column', md: 'row' } }}>
                          <Typography variant="h6" color="textPrimary" fontWeight="bold" sx={{ fontSize: '1.25rem', lineHeight: 1 }}>
                            {Math.floor(card.sale_price) === 0 ? 'Free' : `₹${Math.floor(card.sale_price)}`}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through', fontSize: '0.875rem' }}>
                            M.R.P: ₹{card?.original_price.toLocaleString()}
                          </Typography>
                        </Box>
                        <Typography sx={{ fontSize: '1rem' }}>{card.card_name}</Typography>
                        <Rating value={card.card_rating} readOnly />
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                onClick={() => navigate(`/cards/shop-opening`)}
                variant="contained"
                color="secondary"
                sx={{
                  textTransform: 'none',
                  backgroundColor: isDarkMode ? '#BB86FC' : '#ef5350',
                  borderRadius: '8px',
                  padding: '10px 20px',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: isDarkMode ? '#9965f4' : '#d32f2f'
                  }
                }}
              >
                Browse More Shop Opening Invites
              </Button>
            </Box>
          </Box>
        </>
      )}



      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <img src={selectedImage} alt="Selected" style={{ width: "100%", height: "auto" }} />
      </Dialog>




      {/* Testimonials Section */}
      <Box
        sx={{
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : '#d4e4ed',
          padding: 6,
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            ...sectionHeadingStyle,
            fontSize: { xs: '1.8rem', md: '2rem' }
          }}
        >
          What Customers Say About Us
        </Typography>
        <TestimonialCarousel
          testimonials={[
            {
              text: '"A Game Changer!"',
              msg: 'RajOffset has revolutionized how we create invitations. The designs are stunning, the process is seamless, and the results always leave our guests in awe. Truly a game-changer in the world of digital invitations!',
              author: 'Shreya Singh'
            },
            {
              text: '"Simply Brilliant!"',
              msg: 'From weddings to corporate events, RajOffset has been our go-to platform. The customization options are endless, and the quality is unmatched. Our invitations have never looked this professional and beautiful!',
              author: 'Akash Singh'
            },
            {
              text: '"Exceeded Expectations!"',
              msg: 'I was blown away by the level of detail and creativity RajOffset offers. The platform is intuitive, the designs are modern, and the support team is always ready to help. Our events have never been this memorable!',
              author: 'Ankit Mishra'
            },
            {
              text: '"A Must-Have Tool!"',
              msg: 'Whether you\'re planning a small gathering or a grand celebration, RajOffset is the ultimate solution. The designs are elegant, the process is hassle-free, and the results are always spectacular. Highly recommended!',
              author: 'Sahil Patel'
            },
            {
              text: '"Incredible Service!"',
              msg: 'The team at RajOffset went above and beyond to create the perfect invitation for our wedding. The attention to detail and quick turnaround time was impressive. Our guests couldn\'t stop complimenting the design!',
              author: 'Priya Sharma'
            },
            {
              text: '"Best in the Business!"',
              msg: 'I\'ve used several invitation services before, but RajOffset stands out for their creativity and professionalism. The user-friendly platform made the entire process a breeze. Highly recommend them to anyone!',
              author: 'Rahul Verma'
            },
            {
              text: '"Exceptional Quality!"',
              msg: 'The quality of invitations from RajOffset is unmatched. They helped us create a unique design that perfectly captured the essence of our event. The support team was always available to help with any questions.',
              author: 'Neha Gupta'
            }
          ]}
          isDarkMode={isDarkMode}
        />
      </Box>

      {/* App Download Section */}
      <Box
        sx={{
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#f7d5cc',
          textAlign: 'center',
          padding: 2,
        }}
      >
        <Typography variant='h6'>Download our mobile app from</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Typography
            variant="h6"
            noWrap
            component="div" sx={{ paddingLeft: 1, color: isDarkMode ? 'white' : '#003366', fontWeight: 'bold' }}
            onClick={() => navigate(`/android-app`)}
          >
            <img src={GooglePlay} height={'50px'} alt="Logo" />
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ paddingLeft: 1, color: isDarkMode ? 'white' : '#003366', fontWeight: 'bold' }}
            onClick={() => navigate(`/ios-app`)}
          >
            <img src={appleStore} height={'45px'} alt="Logo" />
          </Typography>
        </Box>

      </Box>
    </div>
  );
};

export default HomePage;
