import React, { useState } from 'react';
import { 
  Button, 
  TextField, 
  Container, 
  Typography, 
  Box, 
  CircularProgress, 
  InputAdornment, 
  Select, 
  MenuItem,
  Paper,
  Fade,
  Divider,
  FormControl,
  Grid
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { LogIn } from '../redux/actions/authActions';
import countries from "../components/countries.json";
import styled from '@emotion/styled';
import PhoneIcon from '@mui/icons-material/Phone';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const StyledButton = styled(Button)`
  background: linear-gradient(45deg, #2196F3 30%, #21CBF3 90%);
  box-shadow: 0 3px 5px 2px rgba(33, 203, 243, .3);
  padding: 12px 0;
  transition: all 0.3s ease;
  
  &:hover {
    background: linear-gradient(45deg, #1976d2 30%, #0ba3d4 90%);
    box-shadow: 0 4px 10px 2px rgba(33, 203, 243, .4);
    transform: translateY(-2px);
  }
`;

const StyledPaper = styled(Paper)`
  padding: 40px;
  border-radius: 18px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  background: linear-gradient(145deg, #ffffff, #f5f8ff);
  border-top: 4px solid #2196F3;
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
  }
`;

const CountryCodeSelect = styled(Select)`
  height: 56px;

  .MuiSelect-select {
    display: flex;
    align-items: center;
    padding: 14px 10px;
    border-radius: 12px;
    background-color: #f5f8ff;
    height: 56px;
  }
  
  .MuiOutlinedInput-root {
    height: 56px;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
    border-radius: 12px;
  }
  
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #2196F3;
  }
`;

const PhoneTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: 56px;
    border-radius: 12px;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
  }
  
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #2196F3;
  }
`;

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [otpMethod, setOtpMethod] = useState('sms');

  // Get the redirect URL from location state
  const redirectUrl = location.state?.redirectUrl;
  const requiresCardAttempt = location.state?.requiresCardAttempt;
  const cardData = location.state?.cardData;

  const handleSubmitNumber = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await dispatch(LogIn({ 
        mobile: mobileNumber, 
        tenant: "rajoffset", 
        country_code: selectedCountry?.dial_code, 
        otp_method: otpMethod 
      }));
      
      if (response && response.data && response.data.RefId) {
        navigate(`/verify-otp`, {
          state: {
            referenceId: response.data.RefId,
            mobileNumber: response.data.mobile,
            tenant: response.data.tenant,
            message: response.message,
            countryCode: response.data.country_code,
            otpMethod: otpMethod,
            redirectUrl: redirectUrl,
            requiresCardAttempt: requiresCardAttempt,
            cardData: cardData
          }
        });
      } else {
        setError('Please refresh your browser.');
      }
    } catch (err) {
      setError('Failed to send OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  //countries change
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [selectedPhoneLength, setSelectedPhoneLength] = useState(countries[0].phoneLength);

  const handleCountryChange = (event) => {
    const country = countries.find((c) => c.dial_code === event.target.value);
    setSelectedCountry(country);
    setSelectedPhoneLength(country?.phoneLength);
  }

  return (
    <Container component="main" maxWidth="sm">
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          my: 8
        }}
      >
        <Fade in={true} timeout={800}>
          <StyledPaper elevation={3}>
            <Box textAlign="center" mb={3}>
              <Typography 
                component="h1" 
                variant="h4" 
                fontWeight={600} 
                color="primary"
                sx={{ mb: 1 }}
              >
                Welcome
              </Typography>
              
              <Typography 
                variant="body1" 
                color="text.secondary"
                sx={{ mb: 2 }}
              >
                {redirectUrl ? 
                  "Login to access your invitation card" : 
                  "Please login to continue to your account"}
              </Typography>
              
              <Divider sx={{ my: 2 }} />
              
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  my: 2,
                  gap: 1
                }}
              >
                <LockOpenIcon color="primary" />
                <Typography variant="body2" color="text.secondary">
                  Secure OTP Authentication
                </Typography>
              </Box>
            </Box>

            {/* Phone Input with separated country code */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 500 }}>
                Mobile Number
              </Typography>
              
              <Grid container spacing={2}>
                <Grid item xs={4} sm={3}>
                  <FormControl fullWidth variant="outlined">
                    <CountryCodeSelect
                      value={selectedCountry.dial_code}
                      onChange={handleCountryChange}
                      renderValue={() => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <img 
                            src={selectedCountry.flag} 
                            alt={selectedCountry.name} 
                            style={{ 
                              width: 20, 
                              height: 14, 
                              marginRight: 8, 
                              borderRadius: '2px' 
                            }} 
                          />
                          <Typography variant="body2" fontWeight={500}>
                            {selectedCountry.dial_code}
                          </Typography>
                        </Box>
                      )}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 300,
                            borderRadius: '16px',
                            boxShadow: '0 8px 16px rgba(0,0,0,0.1)'
                          }
                        }
                      }}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.code} value={country.dial_code} sx={{ py: 1 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <img 
                              src={country.flag} 
                              alt={country.name} 
                              style={{ 
                                width: 24, 
                                height: 16, 
                                borderRadius: '2px' 
                              }} 
                            />
                            <Box>
                              <Typography noWrap variant="body2">
                                {country.name}
                              </Typography>
                              <Typography 
                                color="text.secondary" 
                                variant="caption" 
                                fontWeight={500}
                              >
                                {country.dial_code}
                              </Typography>
                            </Box>
                          </Box>
                        </MenuItem>
                      ))}
                    </CountryCodeSelect>
                  </FormControl>
                </Grid>
                
                <Grid item xs={8} sm={9}>
                  <PhoneTextField
                    fullWidth
                    required
                    placeholder={`${selectedPhoneLength} digits number`}
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value.replace(/[^0-9]/g, ''))}
                    autoFocus
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PhoneIcon color="action" />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
              
              <Typography 
                variant="caption" 
                color="text.secondary" 
                sx={{ mt: 1, display: 'block' }}
              >
                {`Enter a ${selectedPhoneLength}-digit mobile number to receive OTP`}
              </Typography>
            </Box>

            <Typography 
              variant="caption" 
              color="text.secondary" 
              align="center" 
              display="block" 
              sx={{ mb: 3 }}
            >
              We'll send you a one-time password to verify your mobile number
            </Typography>

            <StyledButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmitNumber}
              disabled={mobileNumber.length !== selectedPhoneLength || isNaN(mobileNumber) || loading}
              sx={{ mt: 1, mb: 2, borderRadius: '12px', fontSize: '1rem' }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Send OTP'}
            </StyledButton>
            
            {error && (
              <Typography 
                color="error" 
                sx={{ mt: 2, textAlign: 'center' }}
              >
                {error}
              </Typography>
            )}
          </StyledPaper>
        </Fade>
      </Box>
    </Container>
  );
};

export default Login;
