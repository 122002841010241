import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const getIndividualCardRequest = () => ({
    type: actionTypes.GET_INDIVIDUAL_CARD_REQUEST,
});
export const getIndividualCardSuccess = (data) => ({
    type: actionTypes.GET_INDIVIDUAL_CARD_SUCCESS,
    payload: data,
});

export const getIndividualCardFailure = (error) => ({
    type: actionTypes.GET_INDIVIDUAL_CARD_FAILURE,
    payload: error
});

export const getIndividualCardData = (id) => {
    console.log("id inside bundle action", id);
    return async(dispatch) => {
        dispatch(getIndividualCardRequest());
        try{
            const {data} = await api.getIndividualCard(id);
            console.log("data in action", data);
            dispatch(getIndividualCardSuccess(data));
            return data;
        }catch(error) {
            dispatch(getIndividualCardFailure(error));
        }
    }
}

export const individualCardPaymentSuccess = async (paymentData) => {
    const { data } = await api.individualCardPaymentSuccess(paymentData);
    return data;
};

export const bundlePaymentBeacon = async (bundle_id) => {
    const { data } = await api.bundlePaymentBeacon(bundle_id);
    return data;
};